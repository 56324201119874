<header id="header">
  <div class="container">
    <div class="row align-items-center justify-content-between d-flex">
      <div id="logo">
        <a href="index.html">
          <img id="img-logo" src="./assets/img/logo.png" alt="" title="" />
        </a>
      </div>
      <nav id="nav-menu-container">
        <ul class="nav-menu">
          <li class="menu-active">
            <a [routerLink]="['/home']">Home</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'info' }">Info</a>
          </li> -->
          <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'feature' }">Features</a>
          </li>
          <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'map-coverage' }">Coverage Area</a>
          </li>
          <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'products' }">Products</a>
          </li>
          <li>
            <a [routerLink]="['/events']">News & Events</a>
          </li>
          <li>
            <a [routerLink]="['/elearning']">E-Learning</a>
          </li>
          <li>
            <a [routerLink]="['/usecase']">Use Cases</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'aboutus' }">About us</a>
          </li> -->
          <li>
            <br><!-- <a [routerLink]="['/home']" [queryParams]="{ section: 'aboutus' }">About us</a> -->
          </li>
          <li class="hidden-md-up">
            <a href="javascript:void(0)">Support</a>
          </li>
          <li class="hidden-md-up">
            <a [routerLink]="['/faq']">&nbsp;&nbsp;> FAQ</a>
          </li>
          <li class="hidden-md-up">
            <a [routerLink]="['/download']">&nbsp;&nbsp;> LoRa Document</a>
          </li>
          <li class="hidden-sm-down">
            <div class="dropdown">
              <a href="javascript:void(0)">Support</a>
              <div class="dropdown-content">
                <a [routerLink]="['/faq']">FAQ</a>
                <a [routerLink]="['/download']"> LoRa Document</a>
              </div>
            </div>
          </li>

        </ul>

      </nav>
      <!-- #nav-menu-container -->
      <div id="language">
          <a href="javascript:void(0)" (click)="changeLanguage('en')"> <img [ngClass]="{'img-grey':this.language == 'th'}"
              id="img-lang" src="./assets/img/en.png" alt="" title="" /></a>
          <a href="javascript:void(0)" (click)="changeLanguage('th')"><img [ngClass]="{'img-grey':this.language == 'en'}"
              id="img-lang" src="./assets/img/th.png" alt="" title="" /></a>
        </div>
    </div>
  </div>
</header>
<!-- #header -->

<!-- About Generic Start -->
<!-- <div class="main-wrapper">
  <br>
  <br>
  <br> -->
<section id="faq-area-id" class="section-gap">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="menu-content pb-60 col-lg-10">
        <div class="title text-center">
          <h1 class="mb-10">FAQ</h1>
          <!-- <p>Who are in extremely love with eco friendly system.</p> -->
        </div>
      </div>
    </div>
    <div class="row" *ngFor="let item of faqLst">
      <div class="col-md-12 feature-box">
        <h3 class="about-title mb-30"> {{item.question | translate}}</h3>
        <p style="white-space: pre-line;" class="font-para-16 word-wrap"> {{item.answer | translate}}</p>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-12 feature-box">
        <h3 class="about-title mb-30">อุปกรณ์ LoRa ชนิดใดที่สามารถนำมาเชื่อมต่อ LoRa IoT by CAT ได้บ้าง ?</h3>
        <p class="font-para-16">- Device (อุปกรณ์ที่สามารถเชื่อมต่อกับ LoRa)</p>
        <p class="font-para-16">- เป็นไปตามมาตรฐาน LoRaWAN Specification 1.1</p>
        <p class="font-para-16">- Region Profile AS923</p>
        <p class="font-para-16">- Activation Mode ทั้ง ABP และ OTAA</p>
        <p class="font-para-16">- Adaptive Data Rate (ADR) รองรับทั้ง ON และ OFF</p>
        <p class="font-para-16">- รองรับLoRa Class ทั้ง Class A และ Class C</p>
        <p class="font-para-16">- สำหรับกำลังส่ง และ Duty Cycle</p>
        <p class="font-para-16 word-wrap">การใช้งานเป็นไปตามข้อกำหนดตามประกาศราชกิจจานุเบกษา เล่ม 134 ตอนพิเศษ 289 ง
          หน้า 51
          ที่ประกาศไว้
          ณ วันที่ 24 พฤศจิกายน 2560 เรื่อง มาตรฐานทางเทคนิคของเครื่องโทรคมนาคมและอุปกรณ์ สําหรับเครื่องวิทยุคมนาคม
          ที่ไม่ใช่ประเภท
          Radio Frequency Identification: RFID ซึ่งใช้คลื่นความถี่ย่าน 920- 925 เมกะเฮิรตซ์
          (http://www.ratchakitcha.soc.go.th/DATA/PDF/2560/E/289/51.PDF)</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 feature-box">
        <h3 class="about-title mb-30">ความถี่ที่ใช้ กำหนด Region Profile เป็น AS923 แล้วยังใช้งานไม่ได้ ?</h3>
        <p class="font-para-16">ในอุปกรณ์บางรุ่นอาจจะต้องกำหนดค่าความถี่ให้กับ แต่ละช่องสัญญาณเอง
          สามารถกำหนดค่าความถี่ของแต่ละช่องสัญญาณได้ตาม
          ข้อมูลความถี่
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 feature-box">
        <h3 class="about-title mb-30">ความปลอดภัยของข้อมูล และการเชื่อมต่อ ?</h3>
        <p class="font-para-16">มาตรฐานการเชื่อมต่อ กำหนดให้มี Key ตามมาตรฐาน AES-128 ประกอบด้วย Key ของอุปกรณ์
          โครงข่าย
          และ Application ทั้งนี้ต้องกำหนด ตามโหมดการเข้าใช้งานที่ถูกต้อง
        </p>
        <h4>PIC</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 feature-box">
        <h3 class="about-title mb-30">การใช้งานโครงข่าย LoRa IoT by CAT ผู้ใช้งานต้องซื้ออุปกรณ์ที่ CAT
          ขาย/จัดเตรียมให้เท่านั้นหรือไม่
          ?</h3>
        <p class="font-para-16">โครงข่าย LoRa IoT by CAT เป็นโครงข้ายที่เป็นไปตามมาตรฐาน LoRaWAN มีข้อกำหนด มาตรฐานจาก
          LoRa
          Alliance ดังนี้ให้ผู้ใช้งานสามารถมั่นใจได้ว่า อุปกรณ์ที่ผ่านมาตรฐาน Certifiled จาก LoRa Alliance
          สามารถใช้งานได้ทั้งหมด
          โดยสามารถเช็ครายละเอียดเพิ่มเติมได้ที่หัวข้อง Device เพื่อกำหนดค่าให้ตรง</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 feature-box">
        <h3 class="about-title mb-30">การใช้งานโครงข่าย LoRa IoT by CAT ผู้ใช้จำเป็นต้องส่งข้อมูลเข้า
          Applicationตัวอย่าง
          เท่านั้นหรือไม่ ?
        </h3>
        <p class="font-para-16">ไม่จำเป็น โครงข่าย LoRa IoT by CAT มีหัวข้อ Routing Profile ให้ผู้ใช้งานกำหนดปลายทางของ
          Application
          ที่ต้องการส่งข้อมูลไป ผู้ใช้งานสามารถพัฒนา Application / หรือประยุกต์ใช้งาน Application
          ที่มีอยู่แล้วได้ตามความถนัด
          โดยรองรับโปรโตคอลทาง IoT ที่นิยมใช้อย่าง HTTP และ MQTT</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 feature-box">
        <h3 class="about-title mb-30">โครงข่าย LoRa IoT by CAT รองรับการสื่อสาร LoRa Class ใดบ้าง ?</h3>
        <p class="font-para-16">รองรับ Class A และ C</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 feature-box">
        <h3 class="about-title mb-30">โครงข่าย LoRa IoT by CAT สามารถส่งข้อมูลจาก Application กลับมายัง อุปกรณ์ IoT
          ได้หรือไม่
          ?</h3>
        <p class="font-para-16">รองรับการใช้งานทั้ง โหมด Uplink และ Downlink</p>
        <p class="font-para-16">- Uplink คือ การส่งข้อมูลจาก อุปกรณ์ ผ่านโครงข่าย เพื่อส่งต่อไปยัง Application ปลายทาง
        </p>
        <p class="font-para-16">- Downlink คือ การส่งข้อมูลจาก Application กลับมายัง อุปกรณ์</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 feature-box">
        <h3 class="about-title mb-30">ทำไมการส่งข้อมูลจาก Application กลับมายัง อุปกรณ์ IoT มีความหน่วงในการส่งข้อมูล ?
        </h3>
        <p class="font-para-16">ขึ้นกับ LoRa Class ที่กำหนด</p>
        <p class="font-para-16">- Class A - กำหนดให้ช่วงการส่งเป็นไปตาม Duty Cycle ที่กำหนดไว้
          โดยเมื่อส่งเสร็จก็จะปิดกระบวนการ
          การรับ-ส่งสื่อสารข้อมูล ทำให้ที่อุปกรณ์ประหยัดพลังงานเป็นอย่างมาก ดังนั้น หากส่ง Downlink
          กลับมาก็จะต้องรอบการส่งครั้งต่อไป</p>
        <p class="font-para-16">- Class C - กำหนดให้ช่วงการส่งเป็นไปตาม Duty Cycle ที่กำหนดไว้
          โดยเมื่อส่งเสร็จเปิดช่องสัญญาณรอรับค้างไว้
          Class C นี้ จะใช้พลังงานมากขึ้น สำหรับการเปิดช่องสัญญาณรอรับค้างไว้ แต่มีข้อดีที่ สามารถสั่งงานได้ทันที</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 feature-box">
        <h3 class="about-title mb-30">ในแต่ละ Account สามารถมีอุปกรณ์ได้มากกว่า 1 อุปกรณ์หรือไม่ ?</h3>
        <p class="font-para-16">สามารถมีได้หลายอุปกรณ์ ผู้ใช้งานสามารถติดต่อ เพิ่มอุกรณ์ใช้งานได้ โดยจะได้รับ Voucher
          ให้นำมากรอกในเมนู
          Connectivity เมื่อทำการActivate Voucher นั่นๆ Voucher จะกลายเป็น Connectivity Instance เพิ่อขึ้นมาให้พร้อมใช้
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 feature-box">
        <h3 class="about-title mb-30">ทำไมการเชื่อมต่อ ใน Activation Mode เป็น OTAA ถึงใช้เวลานาน ?</h3>
        <p class="font-para-16">ตามกระบวนการที่จะต้องแลกเปลี่ยน</p>
      </div>
    </div> -->
  </div>
</section>


<div class="content visible-md-block visible-lg-block">
  <div class="corner-ribbon bottom-right sticky green shadow"><a class="banner" [routerLink]="['/elearning']">E - LEARNING</a></div>
</div>