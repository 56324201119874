import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class PubliccallService {


  // readonly rootUrl = 'http://122.155.223.60';
  // readonly rootUrl = 'https://loraiot.cattelecom.com' + this.dataService.rootUrl;
  readonly rootUrl = 'https://lora.ntdigitalsolutions.com/portal';

  reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' })

  constructor(private http: HttpClient) { }

  userAuthentication(userName, password) {
    var data = {
      "username": userName,
      "password": password
    }

    return this.http.post(this.rootUrl + '/iotapi/auth/token', data, { headers: this.reqHeader });
  }

  getUserInfo() {
    // return this.http.get(this.rootUrl + '/iotapi/core/userInfo', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('userToken') }) });
    return this.http.get(this.rootUrl + '/iotapi/core/userInfo');
  }

  getSiteLoRa(lng: any, lat: any) {
    // console.warn(this.rootUrl + '/iotapi/core/baseStations/range?lat=' + lat.toFixed(6) + '&lon=' + lng.toFixed(6));
    return this.http.get(this.rootUrl + '/iotapi/core/baseStations/range?lat=' +  lat.toFixed(6) + '&lon=' + lng.toFixed(6), { headers: this.reqHeader});
  }
  
  serviceRequest(requestobject) {
    var headerReq
    if (requestobject.requireCaptcha) {
      headerReq = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True', 'g-recaptcha-response': requestobject.captchaResponse })
    }
    else {
      headerReq = this.reqHeader
    }

    switch (requestobject.method) {
      case 'GET':
        return this.http.get(this.rootUrl + requestobject.url, { headers: headerReq });
      case 'POST':
        return this.http.post(this.rootUrl + requestobject.url, requestobject.data, { headers: headerReq });
      case 'PUT':
        return this.http.put(this.rootUrl + requestobject.url, requestobject.data, { headers: headerReq });
      case 'DELETE':
        return this.http.delete(this.rootUrl + requestobject.url, { headers: headerReq });
      default:
        break;
    }
  }
}
