import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ScrollToService } from 'ng2-scroll-to-el';
import { EmbedVideoService } from 'ngx-embed-video';

import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-use-case',
  templateUrl: './use-case.component.html',
  styleUrls: ['./use-case.component.css']
})
export class UseCaseComponent implements OnInit {
  language: any;

  yt_iframe_html: any;
  yt_iframe_html_1:any;
  yt_iframe_html_2:any;

  constructor(
    private embedService: EmbedVideoService,
    private router: Router,
    private scrollService: ScrollToService,
    private translator: TranslationService) {

    // this.translate = translate;
    // this.language = localStorage.getItem('lang')
    // this.translate.setDefaultLang(this.language);

    // console.warn("localStorage USECCASE:", this.language);


    // if (this.language != null) {
    //   this.translate.use(this.language);
    // }
    // else {
    //   localStorage.setItem('lang', 'th');
    //   this.translate.setDefaultLang(this.language);
    // }
  }

  // changeLanguage(lang: string) {
  //   console.log("lang:", lang);

  //   this.translate.use(lang);
  //   localStorage.setItem('lang', lang);
  //   this.language = lang;
  // }


  changeLanguage(lang: string) {
    console.log("lang USECASE :", lang);
    this.translator.setLanguage(lang);
    this.language = lang;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

  }

  youtubeWidth: number = 300

  ngOnInit() {
    this.scrollService.scrollTo("#usecase-area-id", 0, 0);

    this.youtubeWidth = document.getElementById("youtube-area").offsetWidth
    
    this.yt_iframe_html=(this.embedService.embed('https://www.youtube.com/watch?v=mv61QaqJm1g', {
      attr: { width: this.youtubeWidth, height: 550 },
    }))

    this.yt_iframe_html_1=(this.embedService.embed('https://www.youtube.com/watch?v=zl0BKj0PsKo', {
      attr: { width: this.youtubeWidth, height: 550 },
    }))

    this.yt_iframe_html_2=(this.embedService.embed('https://www.youtube.com/watch?v=k2T9s523Lg4', {
      attr: { width: this.youtubeWidth, height: 550 },
    }))
  }

  // goToSection(gotoSection) : void {
  //   alert();
  //   switch (gotoSection) {
  //     case "info":
  //       localStorage.setItem("gotoSection", "info");
  //     break;
  //     case "feature":
  //       localStorage.setItem("gotoSection", "feature");
  //     break;
  //     case "aboutus":
  //       localStorage.setItem("gotoSection", "aboutus");
  //     break;
  //     default:
  //       break;
  //   }
  //   this.router.navigate(["/home"]);
  // }

}
