import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ScrollToService } from 'ng2-scroll-to-el';

import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  language: any;
  faqLst: any;

  constructor(
    private scrollService: ScrollToService,
    private router: Router,
    private translator: TranslationService) {


    // this.translate = translate;
    // this.language = localStorage.getItem('lang')
    // this.translate.setDefaultLang(this.language);

    // // console.warn("localStorage FAQ:", this.language);


    // if (this.language != null) {
    //   this.translate.use(this.language);
    // }
    // else {
    //   localStorage.setItem('lang', 'th');
    //   this.translate.setDefaultLang(this.language);
    // }

    this.faqLst = [{
      question: 'FAQ_QUESTION_1',
      answer: 'FAQ_ANSWER_1',
    }, {
      question: 'FAQ_QUESTION_2',
      answer: 'FAQ_ANSWER_2',
    }, {
      question: 'FAQ_QUESTION_3',
      answer: 'FAQ_ANSWER_3',
    }, {
      question: 'FAQ_QUESTION_4',
      answer: 'FAQ_ANSWER_4',
    }, {
      question: 'FAQ_QUESTION_5',
      answer: 'FAQ_ANSWER_5',
    }, {
      question: 'FAQ_QUESTION_6',
      answer: 'FAQ_ANSWER_6',
    }, {
      question: 'FAQ_QUESTION_7',
      answer: 'FAQ_ANSWER_7',
    }, {
      question: 'FAQ_QUESTION_8',
      answer: 'FAQ_ANSWER_8',
    }, {
      question: 'FAQ_QUESTION_9',
      answer: 'FAQ_ANSWER_9',
    }, {
      question: 'FAQ_QUESTION_10',
      answer: 'FAQ_ANSWER_10',
    }, {
      question: 'FAQ_QUESTION_11',
      answer: 'FAQ_ANSWER_11',
    }

    ]
  }

  // changeLanguage(lang: string) {
  //   console.log("lang:", lang);

  //   this.translate.use(lang);
  //   localStorage.setItem('lang', lang);
  //   this.language = lang;
  // }


  changeLanguage(lang: string) {
    // console.log("lang FAQ :", lang);
    this.translator.setLanguage(lang);
    this.language = lang;
  }

  ngOnInit() {
    this.language = localStorage.getItem('lang')
    // console.warn(this.language);

    this.scrollService.scrollTo("#faq-area-id", 0, 0);
  }

  // goToSection(gotoSection) : void {
  //   switch (gotoSection) {
  //     case "info":
  //       localStorage.setItem("gotoSection", "info");
  //     break;
  //     case "feature":
  //       localStorage.setItem("gotoSection", "feature");
  //     break;
  //     case "aboutus":
  //       localStorage.setItem("gotoSection", "aboutus");
  //     break;
  //     default:
  //       break;
  //   }
  //   this.router.navigate(["/home"]);
  // }

}
