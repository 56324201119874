<header id="header">
  <div class="container">
    <div class="row align-items-center justify-content-between d-flex">
      <div id="logo">
        <a href="index.html">
          <img id="img-logo" src="./assets/img/logo.png" alt="" title="" />
        </a>
      </div>
      <nav id="nav-menu-container">
        <ul class="nav-menu">
          <li class="menu-active">
            <a [routerLink]="['/home']">Home</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'info' }">Info</a>
          </li> -->
          <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'feature' }">Features</a>
          </li>
          <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'map-coverage' }">Coverage Area</a>
          </li>
          <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'products' }">Products</a>
          </li>
          <li>
            <a [routerLink]="['/events']">News & Events</a>
          </li>
          <li>
            <a [routerLink]="['/elearning']">E-Learning</a>
          </li>
          <li>
            <a [routerLink]="['/usecase']">Use Cases</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'aboutus' }">About us</a>
          </li> -->
          <li>
            <br><!-- <a [routerLink]="['/home']" [queryParams]="{ section: 'aboutus' }">About us</a> -->
          </li>
          <li class="hidden-md-up">
            <a href="javascript:void(0)">Support</a>
          </li>
          <li class="hidden-md-up">
            <a [routerLink]="['/faq']">&nbsp;&nbsp;> FAQ</a>
          </li>
          <li class="hidden-md-up">
            <a [routerLink]="['/download']">&nbsp;&nbsp;> LoRa Document</a>
          </li>
          <li class="hidden-sm-down">
            <div class="dropdown">
              <a href="javascript:void(0)">Support</a>
              <div class="dropdown-content">
                <a [routerLink]="['/faq']">FAQ</a>
                <a [routerLink]="['/download']"> LoRa Document</a>
              </div>
            </div>
          </li>
        </ul>
      </nav>
      <!-- #nav-menu-container -->
      <!-- <div id="language">
        <a href="javascript:void(0)" (click)="changeLanguage('en')"> <img [ngClass]="{'img-grey':this.language == 'en'}"
            id="img-lang" src="./assets/img/en.png" alt="" title="" /></a>
        <a href="javascript:void(0)" (click)="changeLanguage('th')"><img [ngClass]="{'img-grey':this.language == 'th'}"
            id="img-lang" src="./assets/img/th.png" alt="" title="" /></a>
      </div> -->
    </div>
  </div>
</header>
<!-- #header -->

<!-- About Generic Start -->
<div class="main-wrapper">
  <br>
  <br>
  <br>
  <section id="newsevent-area-id" class="section-gap">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="menu-content pb-60 col-lg-10">
          <div class="title text-center">
            <h1 class="mb-10">News & Events</h1>
            <p>ข่าวสารและเหตุการณ์ที่เกิดขึ้นในช่วงที่ผ่านมา</p>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- <div class="col-lg-6 col-md-6 single-blog" *ngFor="let item of eventLst;let i = index;" [attr.data-index]="i"> -->
        <div style="margin-bottom: 30px;" class="col-lg-6 col-md-6 single-blog" *ngFor="let item of eventLst">
          <img class="img-fluid" src="{{ item.image }}" alt="">
          <br>
          <br>
          <ul class="post-tags">
            <li *ngFor="let tag of item.postTags">
              <a href="">{{tag}}</a>
            </li>
          </ul>
          <h4>{{item.title | translate}}</h4>
          <p class="post-date">{{item.postDate | translate}}</p>
          <!-- <p class="p-justify">{{item.content}}</p> -->
          <p class="container-content p-justify" [class.show]="item?.show">{{item.content | translate}}</p>
          <!-- <button (click)="item.show = !item.show">{{ item?.show ? 'Show less': 'Show More' }}</button> -->
          <a href="javascript:void(0)" (click)="item.show = !item.show">{{ item?.show ? 'Show Less': 'Show More' }}</a>
        </div>
      </div>
    </div>
  </section>
</div>


<div class="content visible-md-block visible-lg-block">
  <div class="corner-ribbon bottom-right sticky green shadow"><a class="banner" [routerLink]="['/elearning']">E - LEARNING</a></div>
</div>