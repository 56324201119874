/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 51, "footer", [["class", "footer-area section-gap"], ["style", "background-image: url('./assets/img/footer-bg2.jpg'); background-repeat: no-repeat; background-size: cover;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 43, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 42, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "single-footer-widget"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Get started"])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "ti-angle-double-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(13, { section: 0 }), i1.ɵpad(14, 1), (_l()(), i1.ɵted(-1, null, [" Home"])), (_l()(), i1.ɵeld(16, 0, null, null, 11, "div", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 10, "div", [["class", "single-footer-widget"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["About us"])), (_l()(), i1.ɵeld(20, 0, null, null, 7, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "i", [["class", "ti-angle-double-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(25, { section: 0 }), i1.ɵpad(26, 1), (_l()(), i1.ɵted(-1, null, [" About us"])), (_l()(), i1.ɵeld(28, 0, null, null, 16, "div", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 15, "div", [["class", "single-footer-widget"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Support"])), (_l()(), i1.ɵeld(32, 0, null, null, 12, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 0, "i", [["class", "ti-angle-double-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 36).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(36, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(37, 1), (_l()(), i1.ɵted(-1, null, [" FAQ"])), (_l()(), i1.ɵeld(39, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(40, 0, null, null, 0, "i", [["class", "ti-angle-double-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 42).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(42, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(43, 1), (_l()(), i1.ɵted(-1, null, [" LoRa Document"])), (_l()(), i1.ɵeld(45, 0, null, null, 0, "div", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(46, 0, null, null, 6, "div", [["class", "footer-copyright"]], null, null, null, null, null)), (_l()(), i1.ɵted(47, null, [" \u00A9 ", ", LoRa IoT "])), i1.ɵppd(48, 2), (_l()(), i1.ɵeld(49, 0, null, null, 0, "i", [["class", "fa fa-paper-plane "]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" by "])), (_l()(), i1.ɵeld(51, 0, null, null, 1, "a", [["href", "https://lora.ntdigitalsolutions.com"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["NATIONAL TELECOM"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 13, 0, "home"); var currVal_3 = _ck(_v, 14, 0, "/home"); _ck(_v, 12, 0, currVal_2, currVal_3); var currVal_6 = _ck(_v, 25, 0, "aboutus"); var currVal_7 = _ck(_v, 26, 0, "/home"); _ck(_v, 24, 0, currVal_6, currVal_7); var currVal_10 = _ck(_v, 37, 0, "/faq"); _ck(_v, 36, 0, currVal_10); var currVal_13 = _ck(_v, 43, 0, "/download"); _ck(_v, 42, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 12).target; var currVal_1 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 24).target; var currVal_5 = i1.ɵnov(_v, 24).href; _ck(_v, 23, 0, currVal_4, currVal_5); var currVal_8 = i1.ɵnov(_v, 36).target; var currVal_9 = i1.ɵnov(_v, 36).href; _ck(_v, 35, 0, currVal_8, currVal_9); var currVal_11 = i1.ɵnov(_v, 42).target; var currVal_12 = i1.ɵnov(_v, 42).href; _ck(_v, 41, 0, currVal_11, currVal_12); var currVal_14 = i1.ɵunv(_v, 47, 0, _ck(_v, 48, 0, i1.ɵnov(_v, 0), _co.year, "yyyy")); _ck(_v, 47, 0, currVal_14); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i4.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i4.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
