var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef, OnInit } from '@angular/core';
import { MouseEvent } from '@agm/core';
import { FormControl } from '@angular/forms';
import { AmChartsService } from "@amcharts/amcharts3-angular";
import { ScrollToService } from 'ng2-scroll-to-el';
import { ActivatedRoute } from '@angular/router';
import AOS from 'aos';
import 'aos/dist/aos.css';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { DragScrollComponent } from 'node_modules/ngx-drag-scroll';
import { PubliccallService } from '../../services/publiccall.service';
// import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../services/translation.service';
import { ToastrManager } from 'ng6-toastr-notifications';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(AmCharts, scrollService, route, publiccallService, 
    // translate: TranslateService,
    translator, toastr) {
        // this.items = db.list('/features').valueChanges();
        // this.items = db.collection('features').valueChanges();
        // console.log(this.items);
        this.AmCharts = AmCharts;
        this.scrollService = scrollService;
        this.route = route;
        this.publiccallService = publiccallService;
        this.translator = translator;
        this.toastr = toastr;
        this.showModalBox = true;
        this.zoom = 15;
        // initial center position for the map
        this.lat = 13.884361;
        this.lng = 100.569738;
        this.markers = [];
        this.urlKml = "http://localhost:8888/cta.kml";
        this.indexProduct = 0;
        this.omnivore = require('node_modules/leaflet-omnivore/leaflet-omnivore.min.js');
        this.itemss = [];
        this.addMarker = false;
        this.allUserMarkers = [];
        this.checkCustomLngLat = false;
        this.coverageMode = 'ckeckcover';
        this.showFormInvoice = false;
        this.chkActualCoverage = false;
        this.chkPlanCoverage = false;
        this.createActualLayer = false;
        this.createPlanlLayer = false;
        this.showActualLayer = false;
        this.showPlanlLayer = false;
        this.mapFullscreen = false;
        this.startMap = false;
        this.showImportFile = false;
        this.listMarker = [];
        this.scroll = function () {
            //handle your scroll here
            //notice the 'odd' function assignment to a class field
            //this is used to be able to remove the event listener
            // console.log('');
            // alert(window.pageYOffset);
            if (window.pageYOffset > 100) {
                document.getElementById('header-sec').classList.add('header-sec-scrolled');
            }
            else {
                document.getElementById('header-sec').classList.remove('header-sec-scrolled');
            }
        };
        // this.itemRef = db.object('features');
        // this.itemRef.snapshotChanges().subscribe(action => {
        //   this.allMarkers = action.payload.val();
        //   // 
        //   for (var item in this.allMarkers) {
        //     // console.log(item);
        //     this.markers.push({
        //       lat: this.allMarkers[item].geometry.coordinates[1],
        //       lng: this.allMarkers[item].geometry.coordinates[0],
        //       draggable: false
        //     });
        //   }
        //   console.log(this.markers);   
        // });
        // this.slickWidth = screen.width
        // this.translate = translate;
        // this.language = localStorage.getItem('lang')
        // this.translate.setDefaultLang(this.language);
        // console.warn("localStorage HOME:", this.language);
        // if (this.language != null) {
        //   this.translate.use(this.language);
        // }
        // else {
        //   localStorage.setItem('lang', 'th');
        //   this.translate.setDefaultLang(this.language);
        // }
        // this.translate.setDefaultLang('th');
        // this.translate.use('th');
        // console.warn("translate HOME:", this.translate.defaultLang);
        this.setSizeSlick();
        this.itemss = [
            {
                title: 'Connectivity',
                image: 'assets/img/lora-package.jpg',
                comment: 'PRODUCT_COMMENT_1',
                type: 'PRODUCT_TYPE_1',
                amount: '1',
                price: 'PRICE_1',
                vat: 'PRODUCT_VAT',
                buy: true
            },
            {
                title: 'Module',
                image: 'assets/img/lora-module.jpg',
                comment: 'PRODUCT_COMMENT_4',
                type: 'PRODUCT_TYPE_4',
                amount: '1',
                price: 'PRICE_4',
                vat: 'PRODUCT_VAT',
                buy: false
            },
            {
                title: 'Starter Kit',
                image: 'assets/img/lora-starterkit-2.jpg',
                comment: 'PRODUCT_COMMENT_2',
                type: 'PRODUCT_TYPE_2',
                amount: '1',
                price: 'PRICE_2',
                vat: 'PRODUCT_VAT',
                buy: false
            },
            {
                title: 'Starter Kit',
                image: 'assets/img/lora-starterkit.jpg',
                comment: 'PRODUCT_COMMENT_3',
                type: 'PRODUCT_TYPE_3',
                amount: '1',
                price: 'PRICE_3',
                vat: 'PRODUCT_VAT',
                buy: false
            },
        ];
    }
    // @HostListener('window:scroll') onScroll() {
    //   this.scrollFunction()
    // }
    // scrollFunction() {
    //   // console.log(document.body.scrollTop + "|" + document.documentElement.scrollTop + "|" + document.documentElement.scrollHeight);
    //   if (document.documentElement.scrollTop <= document.documentElement.scrollHeight - 1500) {
    //     document.getElementById("headerContactUs").style.fontSize = "2vh";
    //     // document.getElementById("headerContactUs").style.maxHeight = "23vh";
    //   } else {
    //     document.getElementById("headerContactUs").style.fontSize = "2.4vh";
    //     // document.getElementById("headerContactUs").style.maxHeight = "25vh";
    //   }
    // }
    HomeComponent.prototype.selectedItem = function (test) {
        // console.log("test:", test);
    };
    // changeLanguage(lang: string) {
    //   console.log("lang:", lang);
    //   this.translate.use(lang);
    //   localStorage.setItem('lang', lang);
    //   this.language = lang;
    // }
    HomeComponent.prototype.changeLanguage = function (lang) {
        this.translator.setLanguage(lang);
        this.language = lang;
        // console.log(  this.translator );
        // console.log( this.language );
    };
    HomeComponent.prototype.onResize = function (event) {
        this.setSizeSlick();
    };
    HomeComponent.prototype.setSizeSlick = function () {
        var maxPer = 100, ePer = 66;
        if (screen.width > 500) {
            ePer = 70;
        }
        else {
            ePer = 92;
        }
        // this.screenWidth = screen.width
        // var a = screen.width - ((10 * screen.width) / maxPer)
        this.slickWidth = (ePer * screen.width) / maxPer;
        // this.slickWidth = a
    };
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.language = localStorage.getItem('lang');
        // Set hide maparea
        // document.getElementById('maparea').style.visibility = 'hidden';
        // document.getElementById('maparea').style.marginTop = '-70vh';
        // this.toastr.infoToastr('เนื่องจากสถานการณ์การแพร่ระบาดของเชื้อไวรัส Covid-19 บริษัท กสท โทรคมนาคม จำกัด (มหาชน) ให้พนักงานปฏิบัติงานภายในที่พัก (Work From Home) จึงแนะนำให้ลูกค้าติดต่อและสอบถามข้อมูลผ่านช่องทาง E-mail : iot@cattelecom.com', 'การแจ้งเตือน', {
        //   toastTimeout: 15000,
        // });
        // var modal = document.getElementById('modal-trouble');
        // modal.style.display = "block";
        // this.toastr.warningToastr('ขออภัยในความไม่สะดวกเนื่องจากระบบ Portal ขัดข้อง ทำให้ไม่สามารถให้บริการได้ในขณะนี้ และทาง CAT TELECOM กำลังดำเนินการแก้ไขปัญหาดังกล่าวอย่างเร่งด่วน', 'การแจ้งเตือน', {
        //   toastTimeout: 15000,
        // });
        mapboxgl.accessToken = 'pk.eyJ1IjoibG9yYWNhdCIsImEiOiJjanRwaDIxN3cwNGxmNDRtc2l3N3dtMWliIn0.MC1CFo2pHcKRmyo0SD_1rQ';
        this.map = new mapboxgl.Map({
            container: 'mapbox',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [100.569738, 13.884361],
            zoom: 8
        });
        this.map.addControl(new MapboxGeocoder({
            accessToken: mapboxgl.accessToken
        }));
        this.map.on('click', function (event) {
            // console.log('status = ' + this.addMarker);
            if (_this.addMarker) {
                _this.getResultSiteLoRa(event.lngLat.lng, event.lngLat.lat);
                // console.log('mark');
            }
        });
        // this.map.on('style.load', function () { 
        //   console.log("--------------------->>>------------");
        // })
        this.markerUserLocate = new mapboxgl.Marker();
        // this.map.setGlobalProperties(this.addMarker)
        this.map.addControl(new mapboxgl.FullscreenControl());
        this.map.on('idle', function () {
            if (!_this.startMap) {
                _this.startMap = true;
                _this.goToCoverage();
            }
        });
        this.map.on('data', function (e) {
            if (e.dataType === 'source' && e.sourceId === 'urban-areas-fill9') {
                document.getElementById("mapbox-loading-cover").style.visibility = "hidden";
            }
        });
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                _this.map.flyTo({
                    center: [position.coords.longitude, position.coords.latitude],
                    zoom: 10
                });
                _this.getResultSiteLoRa(position.coords.longitude, position.coords.latitude);
            });
        }
        this.route
            .queryParams
            .subscribe(function (params) {
            switch (params['section']) {
                case "info":
                    _this.scrollService.scrollTo("#coverage-area-id");
                    break;
                case "products":
                    _this.scrollService.scrollTo("#products", 100, screen.height - 80);
                    break;
                case "feature":
                    _this.scrollService.scrollTo("#features");
                    break;
                case "map-coverage":
                    _this.scrollService.scrollTo("#map-coverage-area-id", 100, screen.height - 80);
                    break;
                case "platform":
                    _this.scrollService.scrollTo("#platform");
                    break;
                case "aboutus":
                    _this.scrollService.scrollTo("#aboutus", 100, 0);
                    // this.scrollService.scrollTo("#aboutus");
                    break;
                default:
                    _this.scrollService.scrollTo("#home");
                    break;
            }
        });
        AOS.init({
            useClassNames: true
        });
        window.addEventListener('scroll', this.scroll, true); //third parameter
        document.getElementById('header-sec').classList.remove('header-sec-scrolled');
        // this.map = new google.maps.Map(document.getElementById('map'), {
        //   center: {lat: -34.397, lng: 150.644},
        //   zoom: 8
        // });
        this.searchControl = new FormControl();
        // this.mapsAPILoader.load().then(() => {
        //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        //     types: ["address"]
        //   });
        //   autocomplete.addListener("place_changed", () => {
        //     this.ngZone.run(() => {
        //       //get the place result
        //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        //       //verify result
        //       if (place.geometry === undefined || place.geometry === null) {
        //         return;
        //       }
        //       //set latitude, longitude and zoom
        //       this.lat = place.geometry.location.lat();
        //       this.lng = place.geometry.location.lng();
        //       this.zoom = 12;
        //     });
        //   });
        // });
    };
    HomeComponent.prototype.ngOnDestroy = function () {
        window.removeEventListener('scroll', this.scroll, true);
    };
    HomeComponent.prototype.openImgModel = function () {
        // alert();
        // Get the modal
        var modal = document.getElementById('myModal');
        // Get the image and insert it inside the modal - use its "alt" text as a caption
        // img = document.getElementById('myImg');
        var modalImg = document.getElementById("img01");
        // captionText = document.getElementById("caption") as HTMLImageElement;
        // span = document.getElementsByClassName("close")[0];
        modal.style.display = "block";
        modalImg.src = './assets/img/LoRa2.png';
        // this.captionText.innerHTML = 'พื้นที่ครอบคลุมของสัญญาณ';
    };
    HomeComponent.prototype.closeImgModel = function () {
        var modal = document.getElementById('myModal');
        modal.style.display = "none";
    };
    HomeComponent.prototype.closePopup = function () {
        var modal = document.getElementById('pop-message');
        modal.style.display = "none";
    };
    HomeComponent.prototype.openCompareModel = function () {
        // modal-compare
        console.log('--- openCompareModel');
        var modal = document.getElementById('modal-compare');
        console.log(modal);
        modal.style.display = "block";
    };
    HomeComponent.prototype.closeCompareModel = function () {
        var modal = document.getElementById('modal-compare');
        modal.style.display = "none";
    };
    HomeComponent.prototype.showMap = function () {
        document.getElementById("title-coverage").style.animation = "moveTitleMap 2s";
        document.getElementById("title-coverage").style.marginTop = "-250px";
        document.getElementById("openmap").style.display = "none";
        document.getElementById("remark-coverage").style.display = "block";
        var mapArea = document.getElementById("map-coverage");
        var iframe = document.createElement('iframe');
        var link = "https://www.google.com/maps/d/embed?mid=10f5K5ekp3gxESohoRbAKoEPhrqasopSd";
        // iframe.style.display = "none";
        iframe.width = "100%";
        iframe.height = "600";
        iframe.setAttribute("src", link);
        mapArea.appendChild(iframe);
    };
    // initMap() {
    //   var map = new google.maps.Map(document.getElementById('map'), {
    //     zoom: 11,
    //     center: {lat: 41.876, lng: -87.624}
    //   });
    //   var ctaLayer = new google.maps.KmlLayer({
    //     url: 'http://googlemaps.github.io/js-v2-samples/ggeoxml/cta.kml',
    //     map: map
    //   });
    // }
    HomeComponent.prototype.nextPro = function () {
        this.indexProduct++;
    };
    HomeComponent.prototype.prevPro = function () {
        this.indexProduct--;
    };
    HomeComponent.prototype.goToInfo = function () {
        var myDiv = document.getElementById('info');
        this.scrollTo(document.body, myDiv.offsetTop, 100);
        // document.getElementById('info').animate({scrollTop: offset()}, 600);
    };
    HomeComponent.prototype.scrollTo = function (element, to, duration) {
        if (duration < 0)
            return;
        var difference = to - element.scrollTop;
        var perTick = difference / duration * 2;
        setTimeout(function () {
            element.scrollTop = element.scrollTop + perTick;
            this.scrollTo(element, to, duration - 2);
        }, 10);
    };
    // For map api
    HomeComponent.prototype.showCheckCover = function () {
        this.coverageMode = 'ckeckcover';
        document.getElementById('maparea').style.visibility = 'visible';
        document.getElementById('maparea').style.marginTop = '0vh';
    };
    HomeComponent.prototype.showMapCover = function () {
        document.getElementById('maparea').style.visibility = 'hidden';
        document.getElementById('maparea').style.marginTop = '-70vh';
        this.coverageMode = 'mapcover';
    };
    HomeComponent.prototype.clickedMarker = function (label, index) {
        // console.log(`clicked the marker: ${label || index}`)
    };
    HomeComponent.prototype.mapClicked = function ($event) {
        this.markers.push({
            lat: $event.coords.lat,
            lng: $event.coords.lng,
            draggable: true
        });
    };
    HomeComponent.prototype.markerDragEnd = function (m, $event) {
        // console.log('dragEnd', m, $event);
    };
    HomeComponent.prototype.userMarker = function () {
        var canvas = this.map.getCanvasContainer();
        var element = document.getElementById("btn-marker");
        if (this.addMarker) {
            element.classList.remove("btn-mapbox-marker");
            canvas.style.cursor = '';
            this.addMarker = false;
        }
        else {
            element.classList.add("btn-mapbox-marker");
            canvas.style.cursor = 'crosshair';
            this.addMarker = true;
        }
    };
    HomeComponent.prototype.customLngLat = function () {
        if ((this.getLongitude != null) && (this.getLatitude != null) && (this.getLongitude >= -180) && (this.getLongitude <= 180) && (this.getLatitude >= -90) && (this.getLatitude <= 90)) {
            this.checkCustomLngLat = true;
            this.getResultSiteLoRa(parseFloat(this.getLongitude), parseFloat(this.getLatitude));
        }
        else {
            alert('Value or something wrong, please check again!!!');
        }
    };
    HomeComponent.prototype.removeMarker = function () {
        for (var i in this.allUserMarkers) {
            this.allUserMarkers[i].remove();
        }
        this.markerUserLocate = [];
    };
    HomeComponent.prototype.moveLeft = function () {
        this.ds.moveLeft();
    };
    HomeComponent.prototype.moveRight = function () {
        this.ds.moveRight();
    };
    HomeComponent.prototype.flyToLocation = function (lng, lat) {
        this.map.flyTo({
            center: [lng, lat]
        });
        this.checkCustomLngLat = false;
    };
    // moveLeftYou() {
    //   // this.ds.moveLeft();
    //   this.ys.moveLeft()
    //   console.log('moveLeft');
    // }
    // moveRightYou() {
    //   this.ys.moveRight()
    //   console.log('moveRight');
    // }
    HomeComponent.prototype.calFromMarker = function () {
        // console.log(this.addMarker);
    };
    HomeComponent.prototype.buyProduct = function (product) {
        var modal = document.getElementById('modal-buy-product');
        modal.style.display = "block";
    };
    HomeComponent.prototype.closeModal = function (modalid) {
        document.getElementById(modalid).style.display = "none";
    };
    HomeComponent.prototype.buyConnectivity = function () {
        window.open('http://shopping.cattelecom.com/Products/CAT+Card/LoRa+IoT+by+CAT/Default.aspx', '_blank');
    };
    HomeComponent.prototype.emailContact = function () {
        this.showFormInvoice = true;
        // var customer, body, quantities, product_codes;    
        // customer = {
        //   name: 'First Last',
        //   email: 'example@example.com',
        //   company: 'Company',
        //   phone_number: 'phone number',
        // }
        // body =  'Name: '+ customer.name;
        // body += '\nEmail: '+ customer.email;
        // body += '\nCompany: '+ customer.company;
        // body += '\nPhone Number: '+ customer.phone_number;
        // product_codes = ['code_1', 'code_2', 'code_3'];
        // quantities = [23, 56, 45];
        // for(var i = 0; i < product_codes.length; i += 1) {
        //   body += '\nProduct Code '+ product_codes[i] +': '+ quantities[i];
        // }
        // var subject = 'Your Subject';
        // window.open('mailto:iot@cattelecom.com?body='+ encodeURIComponent(body) +'&subject='+ encodeURIComponent(subject), 'emailWindow');
        // window.location = 'mailto:iot@cattelecom.com?body='+ encodeURIComponent(body) +'&subject='+ encodeURIComponent(subject);
        // window.open('mailto:iot@cattelecom.com', 'emailWindow');
    };
    HomeComponent.prototype.backFormInvoice = function () {
        this.showFormInvoice = false;
    };
    HomeComponent.prototype.sendEmail = function () {
        var body;
        body = "\n\nข้อมูลการสั่งซื้อ Connectivity\n";
        body += '\nชื่อบุคคล/บริษัทฯ/นิติบุคคล : ' + this.custumerName;
        body += '\nที่อยู่ในการออกใบกำกับภาษี : ' + this.custimerAddress;
        body += '\nเลขประจำตัวผู้เสียภาษี : ' + this.customerId;
        body += '\nอีเมล์ : ' + this.customerEmail;
        body += '\nหมายเลขโทรศัพท์ : ' + this.customerPhone;
        body += '\nจำนวน Connectivity : ' + this.amountConnectivity;
        body += '\n\n\nชื่อ-ที่อยู่ ในการจัดส่งเอกสาร';
        body += '\nชื่อ-สกุล : ' + this.shippingName;
        body += '\nชื่อนิติบุคคล/บริษัท : ' + this.shippingCompanyName;
        body += '\nที่อยู่ในการจัดส่งเอกสาร : ' + this.shippingAddress;
        body += '\nหมายเลขโทรศัพท์ : ' + this.shippingPhone;
        body += '\n\n*** กรุณาแนบหลักฐานการชำระเงินพร้อมกับ e-mail ฉบับนี้ ***';
        var subject = 'สั่งซื้อ Connectivity';
        window.open('mailto:iot@cattelecom.com?body=' + encodeURIComponent(body) + '&subject=' + encodeURIComponent(subject) + '&attachment="/my_location_virtual_path/myfile.lis"', 'emailWindow');
    };
    HomeComponent.prototype.onFileChange = function (event) {
        var reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            var file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = function () {
                // this.form.get('avatar').setValue({
                //   filename: file.name,
                //   filetype: file.type,
                //   value: reader.result.split(',')[1]
                // })
                // console.log(file);
            };
        }
    };
    HomeComponent.prototype.getResultSiteLoRa = function (lng, lat) {
        // console.log(lng + "|" + lat);
        // var date = moment(data.lastUpdated).format("DD/MM/YYYY");
        this.markerUserLocate = null;
        var msgpopup = 'You Are Here';
        if (this.checkCustomLngLat) {
            this.flyToLocation(lng, lat);
            msgpopup = 'Result Location';
        }
        var popup = new mapboxgl.Popup({ closeOnClick: true, offset: 37 })
            .setLngLat([lng, lat])
            // <div style="text-align:center">
            // .setHTML('<h3 style="text-align:center"><i class="ti-signal" style="font-size: 30px;"></i>&nbsp;' + data.range + '</h3><br><p>(Last Updated : ' + date + ')</p>')
            .setHTML('<p></p><h5 style="text-align:center">' + msgpopup + '</h5>')
            .addTo(this.map);
        var marker = new mapboxgl.Marker()
            .setLngLat([lng, lat])
            .togglePopup(popup)
            .addTo(this.map)
            .setPopup(popup);
        this.allUserMarkers.push(marker);
        this.delay(5000);
        // this.viewActualCoverage()
        // this.publiccallService.getSiteLoRa(lng, lat).subscribe(
        //   (data: any) => {
        //     // console.log("data:", data);
        //     var date = moment(data.lastUpdated).format("DD/MM/YYYY");
        //     this.markerUserLocate = null
        //     var popup = new mapboxgl.Popup({ closeOnClick: true, offset: 37 })
        //       .setLngLat([lng, lat])
        //       // <div style="text-align:center">
        //       .setHTML('<h3 style="text-align:center"><i class="ti-signal" style="font-size: 30px;"></i>&nbsp;' + data.range + '</h3><br><p>(Last Updated : ' + date + ')</p>')
        //       .addTo(this.map)
        //     var marker = new mapboxgl.Marker()
        //       .setLngLat([lng, lat])
        //       .togglePopup(popup)
        //       .addTo(this.map)
        //       .setPopup(popup)
        //     var layers = this.map.getStyle().layers;
        //     // Find the index of the first symbol layer in the map style
        //     var firstSymbolId;
        //     for (var i = 0; i < layers.length; i++) {
        //       if (layers[i].type === 'symbol') {
        //         firstSymbolId = layers[i].id;
        //         break;
        //       }
        //     }
        //     this.map.addLayer({
        //       'id': 'urban-areas-fill',
        //       'type': 'fill',
        //       'source': {
        //         'type': 'geojson',
        //         'data': './assets/locations/450-128.geojson'
        //       },
        //       'layout': {},
        //       'paint': {
        //         'fill-color': '#E66E2C',
        //         'fill-opacity': 0.3
        //       }
        //     }, firstSymbolId);
        //     this.map.addLayer({
        //       'id': 'urban-areas-fill2',
        //       'type': 'fill',
        //       'source': {
        //         'type': 'geojson',
        //         'data': './assets/locations/450-125.geojson'
        //       },
        //       'layout': {},
        //       'paint': {
        //         // 'fill-color': '#F5DF4B',
        //         'fill-color': '#E66E2C',
        //         'fill-opacity': 0.3
        //       }
        //     }, firstSymbolId);
        //     this.map.addLayer({
        //       'id': 'urban-areas-fill3',
        //       'type': 'fill',
        //       'source': {
        //         'type': 'geojson',
        //         'data': './assets/locations/450-120.geojson'
        //       },
        //       'layout': {},
        //       'paint': {
        //         // 'fill-color': '#91E974',
        //         'fill-color': '#E66E2C',
        //         'fill-opacity': 0.5
        //       }
        //     }, firstSymbolId);
        //     this.map.addLayer({
        //       'id': 'urban-areas-fill4',
        //       'type': 'fill',
        //       'source': {
        //         'type': 'geojson',
        //         'data': './assets/locations/450-110.geojson'
        //       },
        //       'layout': {},
        //       'paint': {
        //         // 'fill-color': '#377D47',
        //         'fill-color': '#E66E2C',
        //         'fill-opacity': 0.5
        //       }
        //     }, firstSymbolId);
        //     this.map.addLayer({
        //       'id': 'urban-areas-fill5',
        //       'type': 'fill',
        //       'source': {
        //         'type': 'geojson',
        //         'data': './assets/locations/450-100.geojson'
        //       },
        //       'layout': {},
        //       'paint': {
        //         // 'fill-color': '#73FBFD',
        //         'fill-color': '#E66E2C',
        //         'fill-opacity': 0.5
        //       }
        //     }, firstSymbolId);
        //     this.map.addLayer({
        //       'id': 'urban-areas-fill6',
        //       'type': 'fill',
        //       'source': {
        //         'type': 'geojson',
        //         'data': './assets/locations/450-90.geojson'
        //       },
        //       'layout': {},
        //       'paint': {
        //         // 'fill-color': '#3182F7',
        //         'fill-color': '#E66E2C',
        //         'fill-opacity': 0.7
        //       }
        //     }, firstSymbolId);
        //     this.map.addLayer({
        //       'id': 'urban-areas-fill7',
        //       'type': 'fill',
        //       'source': {
        //         'type': 'geojson',
        //         'data': './assets/locations/450-85.geojson'
        //       },
        //       'layout': {},
        //       'paint': {
        //         // 'fill-color': '#0028F5',
        //         'fill-color': '#E66E2C',
        //         'fill-opacity': 0.7
        //       }
        //     }, firstSymbolId);
        //     this.allUserMarkers.push(marker)
        //     if (this.checkCustomLngLat) {
        //       this.flyToLocation(lng, lat)
        //     }
        //     // this.markerUserLocate.setLngLat([lng, lat])
        //     // this.markerUserLocate.togglePopup(popup)
        //     // this.markerUserLocate.addTo(this.map)
        //     // this.markerUserLocate.setPopup(popup)
        //     // marker.setPopup(popup2) // sets a popup on this marker
        //     // console.warn(popup);
        //   },
        //   (err: HttpErrorResponse) => {
        //     // console.log(err);
        //     //  TO DO someting...
        //     this.errorResponse = err;
        //     // console.warn(this.errorResponse);
        //     var errObject = {
        //       header: this.errorResponse.error.topic,
        //       logref: this.errorResponse.error.logRef,
        //       message: this.errorResponse.error.message + " (ref : " + this.errorResponse.error.logRef + ")",
        //       code: this.errorResponse.error.errorCode
        //     }
        //     // this.showError(errObject);
        //   }
        // );
    };
    // ngAfterViewChecked() {
    //   if (document.getElementById("mapbox")) {
    //     console.log("create");
    //     this.goToCoverage()
    //   }
    // }
    HomeComponent.prototype.goToCoverage = function () {
        // console.log(this.chkPlanCoverage);
        // this.chkPlanCoverage = !this.chkPlanCoverage
        this.chkActualCoverage = !this.chkActualCoverage;
        this.viewPlanCoverage();
        this.viewActualCoverage();
    };
    HomeComponent.prototype.viewActualCoverage = function () {
        this.viewPlanCoverage();
        if (!this.createActualLayer) {
            this.createActualLayer = true;
            this.showActualLayer = true;
            var layers = this.map.getStyle().layers;
            // console.log('------------------+++++>' + layers);
            // Find the index of the first symbol layer in the map style
            var firstSymbolId;
            for (var i = 0; i < layers.length; i++) {
                if (layers[i].type === 'symbol') {
                    firstSymbolId = layers[i].id;
                    // console.log(firstSymbolId);
                    break;
                }
            }
            // this.map.addLayer();
            // this.map.addLayer({
            //   'id': 'urban-areas-fill',
            //   'type': 'fill',
            //   'source': {
            //     'type': 'geojson',
            //     'data': './assets/locations/450-128.geojson'
            //   },
            //   'layout': {},
            //   'paint': {
            //     'fill-color': '#EBAB86',
            //     'fill-opacity': 1
            //   }
            // }, firstSymbolId);
            // this.map.addLayer({
            //   'id': 'urban-areas-fill2',
            //   'type': 'fill',
            //   'source': {
            //     'type': 'geojson',
            //     'data': './assets/locations/450-125.geojson'
            //   },
            //   'layout': {},
            //   'paint': {
            //     // 'fill-color': '#F5DF4B',
            //     'fill-color': '#EBAB86',
            //     'fill-opacity': 1
            //   }
            // }, firstSymbolId);
            // this.map.addLayer({
            //   'id': 'urban-areas-fill3',
            //   'type': 'fill',
            //   'source': {
            //     'type': 'geojson',
            //     'data': './assets/locations/450-120.geojson'
            //   },
            //   'layout': {},
            //   'paint': {
            //     // 'fill-color': '#91E974',
            //     'fill-color': '#EBAB86',
            //     'fill-opacity': 1
            //   }
            // }, firstSymbolId);
            // this.map.addLayer({
            //   'id': 'urban-areas-fill4',
            //   'type': 'fill',
            //   'source': {
            //     'type': 'geojson',
            //     'data': './assets/locations/450-110.geojson'
            //   },
            //   'layout': {},
            //   'paint': {
            //     // 'fill-color': '#377D47',
            //     'fill-color': '#EBAB86',
            //     'fill-opacity': 1
            //   }
            // }, firstSymbolId);
            // this.map.addLayer({
            //   'id': 'urban-areas-fill5',
            //   'type': 'fill',
            //   'source': {
            //     'type': 'geojson',
            //     'data': './assets/locations/450-100.geojson'
            //   },
            //   'layout': {},
            //   'paint': {
            //     // 'fill-color': '#73FBFD',
            //     'fill-color': '#EBAB86',
            //     'fill-opacity': 1
            //   }
            // }, firstSymbolId);
            this.map.addLayer({
                'id': 'urban-areas-fill6',
                'type': 'fill',
                'source': {
                    'type': 'geojson',
                    'data': './assets/locations/LoRa202003-new1.geojson'
                },
                'layout': {},
                'paint': {
                    // 'fill-color': '#3182F7',
                    'fill-color': '#FFD004',
                    'fill-opacity': 0.6
                }
            }, firstSymbolId);
            this.map.addLayer({
                'id': 'urban-areas-fill7',
                'type': 'fill',
                'source': {
                    'type': 'geojson',
                    'data': './assets/locations/LoRa202003-new2.geojson'
                },
                'layout': {},
                'paint': {
                    // 'fill-color': '#0028F5',
                    'fill-color': '#FFD004',
                    'fill-opacity': 0.6
                }
            }, firstSymbolId);
            this.map.addLayer({
                'id': 'urban-areas-fill8',
                'type': 'fill',
                'source': {
                    'type': 'geojson',
                    'data': './assets/locations/LoRa202003-new3.geojson'
                },
                'layout': {},
                'paint': {
                    // 'fill-color': '#0028F5',
                    'fill-color': '#FFD004',
                    'fill-opacity': 0.6
                }
            }, firstSymbolId);
            this.map.addLayer({
                'id': 'urban-areas-fill9',
                'type': 'fill',
                'source': {
                    'type': 'geojson',
                    'data': './assets/locations/LoRa202003-new4.geojson'
                },
                'layout': {},
                'paint': {
                    // 'fill-color': '#8FAFCF',
                    'fill-color': '#FFD004',
                    'fill-opacity': 0.6
                }
            }, firstSymbolId);
            // this.map.addLayer({
            //   'id': 'urban-areas-fill10',
            //   'type': 'fill',
            //   'source': {
            //     'type': 'geojson',
            //     'data': './assets/locations/week01-2020.geojson'
            //   },
            //   'layout': {},
            //   'paint': {
            //     // 'fill-color': '#8FAFCF',
            //     'fill-color': '#EBAB86',
            //     'fill-opacity': 0.6
            //   },
            //   // 'symbol-z-order' : 999
            // }, firstSymbolId);
        }
        else {
            if (this.showActualLayer) {
                this.showActualLayer = false;
                // this.map.setLayoutProperty('urban-areas-fill', 'visibility', 'none');
                // this.map.setLayoutProperty('urban-areas-fill2', 'visibility', 'none');
                // this.map.setLayoutProperty('urban-areas-fill3', 'visibility', 'none');
                // this.map.setLayoutProperty('urban-areas-fill4', 'visibility', 'none');
                // this.map.setLayoutProperty('urban-areas-fill5', 'visibility', 'none');
                this.map.setLayoutProperty('urban-areas-fill6', 'visibility', 'none');
                this.map.setLayoutProperty('urban-areas-fill7', 'visibility', 'none');
                this.map.setLayoutProperty('urban-areas-fill8', 'visibility', 'none');
                this.map.setLayoutProperty('urban-areas-fill9', 'visibility', 'none');
                // this.map.setLayoutProperty('urban-areas-fill10', 'visibility', 'none');
            }
            else {
                this.showActualLayer = true;
                // this.map.setLayoutProperty('urban-areas-fill', 'visibility', 'visible');
                // this.map.setLayoutProperty('urban-areas-fill2', 'visibility', 'visible');
                // this.map.setLayoutProperty('urban-areas-fill3', 'visibility', 'visible');
                // this.map.setLayoutProperty('urban-areas-fill4', 'visibility', 'visible');
                // this.map.setLayoutProperty('urban-areas-fill5', 'visibility', 'visible');
                this.map.setLayoutProperty('urban-areas-fill6', 'visibility', 'visible');
                this.map.setLayoutProperty('urban-areas-fill7', 'visibility', 'visible');
                this.map.setLayoutProperty('urban-areas-fill8', 'visibility', 'visible');
                this.map.setLayoutProperty('urban-areas-fill9', 'visibility', 'visible');
                // this.map.setLayoutProperty('urban-areas-fill10', 'visibility', 'visible');
            }
        }
    };
    HomeComponent.prototype.viewPlanCoverage = function () {
        var rangef = [{ spec: 80, opacity: 1 },
            { spec: 90, opacity: 1 },
            { spec: 100, opacity: 1 },
            { spec: 110, opacity: 1 },
            { spec: 120, opacity: 1 },
            { spec: 125, opacity: 1 },
            { spec: 130, opacity: 1 },
        ];
        if (!this.createPlanlLayer) {
            this.createPlanlLayer = true;
            // this.showPlanlLayer = true
            var layers = this.map.getStyle().layers;
            var firstSymbolId;
            for (var i = 0; i < layers.length; i++) {
                if (layers[i].type === 'symbol') {
                    firstSymbolId = layers[i].id;
                    break;
                }
            }
            for (var i = 0; i < 4; i++) {
                for (var j in rangef) {
                    var surcespec = 'store-spectrum-' + i + '-' + rangef[j].spec;
                    var idlayer = 'area-spectrum-' + i + '-' + rangef[j].spec;
                    // console.log(surcespec + '    ' + idlayer);
                    this.map.addSource(surcespec, {
                        type: 'geojson',
                        data: './assets/locations/2500-' + i + '/geojson/2500-' + i + '-' + rangef[j].spec + '.geojson'
                    });
                    this.map.addLayer({
                        'id': idlayer,
                        'type': 'fill',
                        'source': surcespec,
                        'layout': {},
                        'paint': {
                            'fill-color': '#8FAFCF',
                            'fill-opacity': rangef[j].opacity
                        },
                    }, firstSymbolId);
                    if (!this.showPlanlLayer) {
                        var idlayer = 'area-spectrum-' + i + '-' + rangef[j].spec;
                        this.map.setLayoutProperty(idlayer, 'visibility', 'none');
                    }
                    else {
                        var idlayer = 'area-spectrum-' + i + '-' + rangef[j].spec;
                        this.map.setLayoutProperty(idlayer, 'visibility', 'visible');
                    }
                    this.delay(5000);
                }
            }
            // this.map.addSource('store-spectrum1', {
            //   type: 'geojson',
            //   data: './assets/locations/2500-128.geojson'
            // });
            // this.map.addLayer({
            //   'id': 'urban-areas-fillplan1',
            //   'type': 'fill',
            //   'source': 'store-spectrum1',
            //   'layout': {},
            //   'paint': {
            //     'fill-color': '#2F75BE',
            //     'fill-opacity': 0.3
            //   }
            // }, firstSymbolId);
        }
        else {
            if (!this.chkPlanCoverage) {
                // this.chkPlanCoverage = true
                for (var i = 0; i < 4; i++) {
                    for (var j in rangef) {
                        var idlayer = 'area-spectrum-' + i + '-' + rangef[j].spec;
                        this.map.setLayoutProperty(idlayer, 'visibility', 'none');
                    }
                }
            }
            else {
                // this.chkPlanCoverage = false
                for (var i = 0; i < 4; i++) {
                    for (var j in rangef) {
                        var idlayer = 'area-spectrum-' + i + '-' + rangef[j].spec;
                        this.map.setLayoutProperty(idlayer, 'visibility', 'visible');
                    }
                }
            }
        }
    };
    HomeComponent.prototype.changeListener = function (files) {
        var _this = this;
        if (files && files.length > 0) {
            // document.getElementById('modal-multi-device').style.display = "block";
            var file = files.item(0);
            document.getElementById('file-upload-wrapper').setAttribute('data-text', file.name);
            var reader_1 = new FileReader();
            reader_1.readAsText(file);
            reader_1.onload = function (e) {
                var csv = reader_1.result;
                var allTextLines = csv.toString().split(/\r|\n|\r/);
                var headers = allTextLines[0].split(',');
                for (var i = 1; i < allTextLines.length; i++) {
                    var obj = {};
                    var data = allTextLines[i].split(",");
                    if (data.length === headers.length) {
                        var dataobj = {
                            name: data[1],
                            latitude: data[2],
                            longitude: data[3]
                        };
                        _this.drawMarkers(dataobj);
                    }
                }
            };
        }
    };
    HomeComponent.prototype.showViewImportFile = function (event) {
        // console.log(event);
    };
    HomeComponent.prototype.drawMarkers = function (data) {
        var htmlpopup = '<div style="width: 200px; color: black;">';
        htmlpopup += '<div class="row"><div class="col-5"><label>Place : </label></div><div class="col-7">' + data.name + '</div></div>';
        htmlpopup += '<div class="row"><div class="col-5"><label>Latitude : </label></div><div class="col-7">' + data.latitude + '</div></div>';
        htmlpopup += '<div class="row"><div class="col-5"><label>Longitude : </label></div><div class="col-7">' + data.longitude + '</div></div>';
        htmlpopup += '</div>';
        var popup = new mapboxgl.Popup({ offset: 25 })
            .setHTML(htmlpopup);
        var marker = new mapboxgl.Marker()
            .setLngLat([data.longitude, data.latitude])
            .setPopup(popup) // sets a popup on this marker
            .addTo(this.map);
        this.listMarker.push(marker);
    };
    HomeComponent.prototype.removeAllMarker = function () {
        for (var item in this.listMarker) {
            this.listMarker[item].remove();
        }
        document.getElementById('file-upload-wrapper').setAttribute('data-text', "Select your file");
        var inputfile = document.getElementById("file-upload-field");
        inputfile.value = "";
    };
    HomeComponent.prototype.downloadCSV = function (args, csvcase) {
        var data, filename, link, csv;
        if (csvcase == 'new') {
            var exampleData = [
                {
                    "No": "",
                    "Name": "",
                    "Latitude": "",
                    "Longitude": "",
                }
            ];
            csv = this.convertArrayOfObjectsToCSV({
                data: exampleData
            });
        }
        if (csv == null)
            return;
        filename = args.filename || 'export.csv';
        if (!csv.match(/^data:text\/csv/i)) {
            csv = 'data:text/csv;charset=utf-8,' + csv;
        }
        data = encodeURI(csv);
        link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', filename);
        link.click();
    };
    HomeComponent.prototype.convertArrayOfObjectsToCSV = function (args) {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;
        data = args.data || null;
        if (data == null || !data.length) {
            return null;
        }
        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';
        keys = Object.keys(data[0]);
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0)
                    result += columnDelimiter;
                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    };
    HomeComponent.prototype.notifyToast = function (datainfo) {
        if (datainfo.payload.healthState == "ACTIVE") {
            this.toastr.successToastr('Base station id :' + datainfo.payload.baseStationId, 'Active!', {
                toastTimeout: 10000,
                data: { baseStationId: datainfo.payload.baseStationId }
            });
        }
        else {
            this.toastr.errorToastr('Base station id :' + datainfo.payload.baseStationId, 'Error!', {
                toastTimeout: 10000,
                data: { baseStationId: datainfo.payload.baseStationId }
            });
        }
    };
    HomeComponent.prototype.delay = function (milliseconds) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        setTimeout(resolve, milliseconds);
                    })];
            });
        });
    };
    return HomeComponent;
}());
export { HomeComponent };
