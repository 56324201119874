<header id="header">
  <div class="container">
    <div class="row align-items-center justify-content-between d-flex">
      <div id="logo">
        <a href="index.html">
          <img id="img-logo" src="./assets/img/logo.png" alt="" title="" />
        </a>
      </div>
      <nav id="nav-menu-container">
        <ul class="nav-menu">
          <li class="menu-active">
            <a [routerLink]="['/home']">Home</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'info' }">Info</a>
          </li> -->
          <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'feature' }"
              >Features</a
            >
          </li>
          <li>
            <a
              [routerLink]="['/home']"
              [queryParams]="{ section: 'map-coverage' }"
              >Coverage Area</a
            >
          </li>
          <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'products' }"
              >Products</a
            >
          </li>
          <li>
            <a [routerLink]="['/events']">News & Events</a>
          </li>
          <li>
            <a [routerLink]="['/elearning']">E-Learning</a>
          </li>
          <li>
            <a [routerLink]="['/usecase']">Use Cases</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'aboutus' }">About us</a>
          </li> -->
          <li>
            <br /><!-- <a [routerLink]="['/home']" [queryParams]="{ section: 'aboutus' }">About us</a> -->
          </li>
          <li class="hidden-md-up">
            <a href="javascript:void(0)">Support</a>
          </li>
          <li class="hidden-md-up">
            <a [routerLink]="['/faq']">&nbsp;&nbsp;> FAQ</a>
          </li>
          <li class="hidden-md-up">
            <a [routerLink]="['/download']">&nbsp;&nbsp;> LoRa Document</a>
          </li>
          <li class="hidden-sm-down">
            <div class="dropdown">
              <a href="javascript:void(0)">Support</a>
              <div class="dropdown-content">
                <a [routerLink]="['/faq']">FAQ</a>
                <a [routerLink]="['/download']"> LoRa Document</a>
              </div>
            </div>
          </li>
        </ul>
      </nav>
      <!-- #nav-menu-container -->
      <!-- <div id="language">
          <a href="javascript:void(0)" (click)="changeLanguage('en')"> <img [ngClass]="{'img-grey':this.language == 'en'}" id="img-lang" src="./assets/img/en.png" alt="" title="" /></a>  
          <a href="javascript:void(0)"(click)="changeLanguage('th')"><img [ngClass]="{'img-grey':this.language == 'th'}" id="img-lang" src="./assets/img/th.png" alt="" title="" /></a>
      </div> -->
    </div>
  </div>
</header>
<!-- #header -->

<!-- About Generic Start -->
<!-- <div class="main-wrapper">
  <br>
  <br>
  <br> -->
<section id="download-area-id" class="section-gap">
  <div class="container">
    <div class="tab">
      <button class="tablinks" (click)="tabSelect($event, 'docSupport')">
        Document Support
      </button>
      <button class="tablinks" (click)="tabSelect($event, 'devices')">
        Devices
      </button>
    </div>

    <div id="docSupport" class="tabcontent">
      <br />
      <div class="row d-flex justify-content-center">
        <div class="title text-center">
          <h3 class="mb-10">LoRa Document</h3>
          <p></p>
        </div>
      </div>

      <br />
      <p>
        <a
          class="label-bullet"
          data-toggle="collapse"
          href="#loraDoc"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <i class="fa fa-angle-right" aria-hidden="true"></i> LoRa Alliance
        </a>
      </p>
      <div class="collapse" id="loraDoc">
        <div class="card card-body">
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1NSDpMOydtN36VAgKd-cKZzEhSU2rQ_5u/view?usp=sharing"
            ><i class="ti-import"></i> LoRa Alliance Technical 101</a
          >
          <a
            target="_blank"
            href="https://drive.google.com/file/d/12M0RTvcLlx_LrHNo_Z03yu3Ss1D0YgyW/view?usp=sharing"
            ><i class="ti-import"></i> LoRaWAN Regional Parameters 1_0_2</a
          >
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1wWTqdQhkm7hom0iY5vMz4c87d2t66jAf/view?usp=sharing"
            ><i class="ti-import"></i> LoRaWAN Regional Parameters 1_1</a
          >
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1iXXq_Tj-JmpLkQkuHaS4zxNRXIITPyrz/view?usp=sharing"
            ><i class="ti-import"></i> LoRaWAN Specification 1_0_1</a
          >
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1gK5iyjK3SoX47sceH7IHOv1w8fA6DWQ2/view?usp=sharing"
            ><i class="ti-import"></i> LoRaWAN Specification 1_0_2</a
          >
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1GHJtGMXy47sOnBTuYnheCf8Frtd1y2gu/view?usp=sharing"
            ><i class="ti-import"></i> LoRaWAN Specification 1_0</a
          >
          <a
            target="_blank"
            href="https://drive.google.com/file/d/12XnFtiqLGWI8ViFfrTBA-QEphndE9Va4/view?usp=sharing"
            ><i class="ti-import"></i> LoRaWAN Specification 1_1</a
          >
        </div>
      </div>
      <br />

      <p>
        <a
          class="label-bullet"
          data-toggle="collapse"
          href="#nbtcDoc"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <i class="fa fa-angle-right" aria-hidden="true"></i> NBTC
        </a>
      </p>
      <div class="collapse" id="nbtcDoc">
        <div class="card card-body">
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1UVdzPoixGOp8rmS3ftWKDByjdnitsPvA/view?usp=sharing"
            ><i class="ti-import"></i> ราชกิจจานุเบกษา ใช้คลื่นความถี่ย่าน 920 -
            925 เมกะเฮิรตซ์</a
          >
        </div>
      </div>

      <br />
      <p>
        <a
          class="label-bullet"
          data-toggle="collapse"
          href="#loraPlatform"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <i class="fa fa-angle-right" aria-hidden="true"></i> LoRa IoT Platform
        </a>
      </p>
      <div class="collapse" id="loraPlatform">
        <div class="card card-body">
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1pv8wswpUIHbdy_NpPOI3v1vGRsd6nzFj/view"
            ><i class="ti-import"></i> การเชื่อมต่อเข้าระบบ LoRa IoT by CAT
            (Last Updated : 10 May 2019)</a
          >
        </div>
      </div>
      <br />
      <p>
        <a
          class="label-bullet"
          data-toggle="collapse"
          href="#loraWorkshop"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <i class="fa fa-angle-right" aria-hidden="true"></i> Workshop
        </a>
      </p>
      <div class="collapse" id="loraWorkshop">
        <div class="card card-body">
          <a
            target="_blank"
            href="https://drive.google.com/drive/u/0/folders/1GDSj7mOhAnfJqS-VCdi_K8OJI-RSauZ3"
            ><i class="ti-import"></i> Workshop SPU (01/11/2019)</a
          >
        </div>
      </div>
      <br />
      <p>
        <a
          class="label-bullet"
          data-toggle="collapse"
          href="#application-contract"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <i class="fa fa-angle-right" aria-hidden="true"></i> Application /
          Contract
        </a>
      </p>
      <div class="collapse" id="application-contract">
        <div class="card card-body">
          <a
          target="_blank"
          href="https://drive.google.com/file/d/1ywxKbE-WpbLB4XTN0YS2KBz-aJTWbWc_/view?usp=sharing"
          ><i class="ti-import"></i> เงื่อนไขการใช้บริการ LoRa IoT by BT</a
        >
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1qeN3eEErZR2XBHrLO-0415lVCHj92lCA/view?usp=sharing"
            ><i class="ti-import"></i> คำขอใช้บริการ LoRa IoT by BT</a
          >
        </div>
      </div>
    </div>

    <div id="devices" class="tabcontent">
      <br />
      <div class="row d-flex justify-content-center">
        <div class="title text-center">
          <h3 class="mb-10">Devices</h3>
          <p>ข้อมูลความช่วยเหลือของอุปกรณ์ที่สามารถเชื่อมต่อกับเรา</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table id="table-device" class="table">
              <thead>
                <tr>
                  <th>Brand</th>
                  <th></th>
                  <th width="42%">Spec</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h4>STMicroelectronics</h4>
                    <h5>[Starter Kit]</h5>
                    <br />
                    <button class="verify-button" (click)="openImgModel('stm')">
                      <i class="ti-check"></i> Verified
                    </button>
                  </td>
                  <td style="text-align: center">
                    <img id="img-stm32" src="./assets/img/STM32-LoRaWAN.png" />
                  </td>
                  <td>
                    <h5>STM32 B-L072Z-LRWAN1</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>
                        Programmable (Development toolchains, Embedded Platform
                        and Arduino Programming)
                      </li>
                      <li>LoRa Modem (AT Command)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>CMWX1ZZABZ-091 LoRa® module (Murata)</li>
                      <li>SMA and U.FL RF interface connectors</li>
                      <li>Including 50 Ohm SMA RF antenna</li>
                      <li>
                        On-board ST-LINK/V2-1 supporting USB re-enumeration
                        capability
                      </li>
                      <li>
                        Board power supply: (USB bus or external VIN 3.3 V or
                        AAA 3 batteries)
                      </li>
                      <li>4 general-purpose LEDs</li>
                      <li>2 push-buttons (user and reset)</li>
                      <li>ArduinoTM Uno V3 connectors</li>
                      <li>ARM® mbedTM (see http://mbed.org)</li>
                    </ul>
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href="https://drive.google.com/open?id=1qlbYLcMmBy0nJ-DDScZdy3yTmODR_4C_"
                      ><i class="ti-import"></i> Manual for STM32</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Murata</h4>
                    <h5>[Module]</h5>
                    <br />
                    <!-- <button class="verify-button" (click)="openImgModel('stm')"><i class="ti-check"></i> Verified</button> -->
                  </td>
                  <td style="text-align: center">
                    <img id="img-murata" src="./assets/img/murata.jpg" />
                  </td>
                  <td>
                    <h5>Murata CMWX1ZZABZ-XXX</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>
                        Programmable (Development toolchains, Embedded Platform
                        and Arduino Programming)
                      </li>
                      <li>LoRa Modem (AT Command)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>
                        Comprises a SEMTECH SX1276 ultra long range spread
                        spectrum wireless transceiver
                      </li>
                      <li>Processor (MCU) STM32L0 (ARM Cortex M0+ 32 bit)</li>
                      <li>Includes 192kB flash and 20kB RAM</li>
                      <li>Communication Interface (UART, SPI, or I2C)</li>
                      <li>12.5 x 11.6 x 1.76 mm metal shielded package</li>
                      <li>Temperature: operate from -40°C to +85°C</li>
                    </ul>
                  </td>
                  <td>
                    <!-- <a target="_blank" href="https://drive.google.com/open?id=1-B12dmJX4kxx84dF0_PqJ5XCY4iaGt7O"><i
                        class="ti-import"></i>
                      Manual for Murata</a> -->
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Microchip</h4>
                    <h5>[Starter Kit]</h5>
                    <br />
                    <!-- <button class="verify-button" (click)="openImgModel('stm')"><i class="ti-check"></i> Verified</button> -->
                  </td>
                  <td style="text-align: center">
                    <img
                      id="img-microship"
                      src="./assets/img/Microchip-LoRaMote.png"
                    />
                  </td>
                  <td>
                    <h5>Microchip LoRa Mote</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Programmable</li>
                      <li>LoRa Modem (AT Command)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>
                        RN Module ICSPTM Programming Through Holes Module
                        Breakout Header 1, 2
                      </li>
                      <li>Microchip ICSP Programming Through Holes</li>
                      <li>LED controlled by RN GPIO10, GPIO11</li>
                      <li>
                        Backlight LCD Display; 1.2 Inch 128 X 32 Dot Matrix
                      </li>
                      <li>Button Switch (S1:Navigation, S2:Selection)</li>
                      <li>Everlight (ALS-PT19-315C) Ambient Light Sensor</li>
                      <li>MCP1825S – LDO Regulator</li>
                      <li>PIC18LF45K50 8-bit USB XLP Flash 32Kb MCU</li>
                      <li>MCP9700 – Linear Active Thermistor</li>
                      <li>AAA Battery Pack and Power Switch</li>
                    </ul>
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href="https://drive.google.com/open?id=1-B12dmJX4kxx84dF0_PqJ5XCY4iaGt7O"
                      ><i class="ti-import"></i> Manual for Microchip LoRa
                      Mote</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Microchip</h4>
                    <h5>[Module]</h5>
                    <br />
                    <!-- <button class="verify-button" (click)="openImgModel('stm')"><i class="ti-check"></i> Verified</button> -->
                  </td>
                  <td style="text-align: center">
                    <img
                      id="img-microshipModule"
                      src="./assets/img/microchipModule.jpg"
                    />
                  </td>
                  <td>
                    <h5>Microchip RN2903 / RN2483</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>LoRa Modem (AT Command)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>On-board LoRaWAN™ Class A protocol stack</li>
                      <li>ASCII command interface over UART</li>
                      <li>Compact form factor 17.8 x 26.7 x 3 mm</li>
                      <li>
                        Castellated SMT pads for easy and reliable PCB mounting
                      </li>
                      <li>Device Firmware Upgrade (DFU) over UART</li>
                      <li>14 GPIO for control, status, and ADC</li>
                      <li>
                        Highly integrated module with MCU, crystal, EUI-64 Node
                        Identity Serial EEPROM, Radio transceiver with analog
                        front end, and matching circuitry
                      </li>
                      <li>PIC18LF45K50 8-bit USB XLP Flash 32Kb MCU</li>
                      <li>Environmentally friendly, RoHS compliant</li>
                      <li>FCC and IC Certified</li>
                    </ul>
                  </td>
                  <td>
                    <!-- <a target="_blank" href="https://drive.google.com/open?id=1-B12dmJX4kxx84dF0_PqJ5XCY4iaGt7O"><i
                        class="ti-import"></i>
                      Manual for Microchip RN2903</a> -->
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Micro4you.com</h4>
                    <h5>[Arduino Shield]</h5>
                    <br />
                    <!-- <button class="verify-button" (click)="openImgModel('stm')"><i class="ti-check"></i> Verified</button> -->
                  </td>
                  <td style="text-align: center">
                    <img id="img-Micro4you" src="./assets/img/Micro4you.jpg" />
                  </td>
                  <td>
                    <h5>RN2903 LoRa Shield</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>LoRa Modem (AT Command)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>On-board LoRaWAN™ Class A protocol stack</li>
                      <li>RN2903 LoRa Module</li>
                      <li>Arduino Shied and useful connector</li>
                    </ul>
                  </td>
                  <td>
                    <!-- <a target="_blank" href="https://drive.google.com/open?id=1-B12dmJX4kxx84dF0_PqJ5XCY4iaGt7O"><i
                        class="ti-import"></i>
                      Manual for Microchip RN2903</a> -->
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Acsip</h4>
                    <h5>[Module]</h5>
                    <br />
                    <!-- <button class="verify-button" (click)="openImgModel('stm')"><i class="ti-check"></i> Verified</button> -->
                  </td>
                  <td style="text-align: center">
                    <img id="img-acsip" src="./assets/img/acsip.jpg" />
                  </td>
                  <td>
                    <h5>ACSIPS7XS</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>LoRa Modem (AT Command)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>
                        MCU Chipset: STM32L073xZ High Performance ARM Cortex M0,
                        32 bits, RISC Core Operating at 32 MHz Frequency
                      </li>
                      <li>Communication Chipset: SX1276</li>
                      <li>
                        REmbedded memories (Up to 192 Kbytes of flash memory and
                        20 Kbytes of RAM)
                      </li>
                      <li>Chip Package LGA Type (13mm x 11mm x 1.1mm)</li>
                      <li>Tx Power +20 dBm max.</li>
                      <li>Interface Protocol: UART</li>
                      <li>Operating Voltage 3.3 V</li>
                    </ul>
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href="https://drive.google.com/file/d/1qlhLieEVna_lHMFIjdVj7cozb85P6KV9/view?usp=sharing"
                      ><i class="ti-import"></i> Manual for ACSIPS7XS</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Gravitech</h4>
                    <h5>[Starter Kit]</h5>
                    <br />
                    <button
                      class="verify-button"
                      (click)="openImgModel('gravitech')"
                    >
                      <i class="ti-check"></i> Verified
                    </button>
                  </td>
                  <td style="text-align: center">
                    <img id="img-gravitech" src="./assets/img/Gravitech.jpg" />
                  </td>
                  <td>
                    <h5>Gravitech Starter Kit</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>LoRa Modem (AT Command)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>MCU Chipset: ARM Cortex-M0+ CPU up to 48MHz</li>
                      <li>LoRa Module: Acsip S76S</li>
                      <li>
                        Memory 256KB in-system self-programmable Flash, 32KB
                        SRAM Memory
                      </li>
                      <li>Interrupt: EIC, One non-maskable</li>
                      <li>
                        Two-pin Serial Wire Debug (SWD) programming, test and
                        debugging interface
                      </li>
                      <li>Drop in compatible with SAM D20</li>
                      <li>Digital 9 Pin, Analog 6 Pin</li>
                      <li>Hardware Serial, I2C, SPI support</li>
                    </ul>
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href="https://drive.google.com/file/d/1yvA44hA9aT39VeC69HB7Xei_C83IXVT2/view?usp=sharing"
                      ><i class="ti-import"></i> Manual for Gravitech</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>EmOne (Thailand)</h4>
                    <h5>[Arduino Shield]</h5>
                    <br />
                    <button
                      class="verify-button"
                      (click)="openImgModel('emone')"
                    >
                      <i class="ti-check"></i> Verified
                    </button>
                  </td>
                  <td style="text-align: center">
                    <img
                      id="img-iMAS923THshield"
                      src="./assets/img/iMAS923THShield.jpg"
                    />
                  </td>
                  <td>
                    <h5>eMOD iMAS923TH shield</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>LoRa Modem (HCI via UART)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>Main MCU: STM32L151CxU6Axx</li>
                      <li>Arduino Shield size 53.5 x 68.5 x 5.5 mm</li>
                      <li>LoRaTM modulation technology</li>
                      <li>Sensitivity down to -138 dBm</li>
                      <li>UART, SPI and I²C interface</li>
                      <li>Digital inputs and outputs, Analog inputs</li>
                      <li>Supply voltage range from 2.4 to 3.6 V</li>
                      <li>RF interface optimized to 50 Ω</li>
                      <li>Integrated 20 dBm Power Amplifier</li>
                      <li>High link budget up to 155 dB</li>
                      <li>Range up to 15000m (Line of Sight)</li>
                      <li>Certified according to FCC ID: N/A</li>
                      <li>NBTC TS. 1033-2560</li>
                    </ul>
                  </td>
                  <td>
                    <!-- <a target="_blank" href="https://drive.google.com/open?id=1-B12dmJX4kxx84dF0_PqJ5XCY4iaGt7O"><i class="ti-import"></i>
                      Manual for Microchip LoRa Mote</a> -->
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>EmOne (Thailand)</h4>
                    <h5>[Module]</h5>
                    <br />
                    <button
                      class="verify-button"
                      (click)="openImgModel('emone')"
                    >
                      <i class="ti-check"></i> Verified
                    </button>
                  </td>
                  <td style="text-align: center">
                    <img id="img-iMAS923TH" src="./assets/img/iMAS923TH.jpg" />
                  </td>
                  <td>
                    <h5>eMOD iMAS923TH</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>LoRa Modem (HCI via UART)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>Main MCU: STM32L151CxU6Axx</li>
                      <li>Compact module 20.0 x 25.0 x 3.3 mm</li>
                      <li>LoRaTM modulation technology</li>
                      <li>Sensitivity down to -138 dBm</li>
                      <li>UART, SPI and I²C interface1</li>
                      <li>Digital inputs and outputs, Analog inputs</li>
                      <li>Supply voltage range from 2.4 to 3.6 V</li>
                      <li>RF interface optimized to 50 Ω</li>
                      <li>Integrated 20 dBm Power Amplifier</li>
                      <li>High link budget up to 155 dB</li>
                      <li>Range up to 15000m (Line of Sight)</li>
                      <li>Certified according to FCC ID: Q9B409810</li>
                      <li>NBTC TS. 1033-2560 : 68/2018</li>
                      <li>
                        HCI encoding interface for more security between
                        controller
                      </li>
                    </ul>
                  </td>
                  <td>
                    <!-- <a target="_blank" href="https://drive.google.com/open?id=1-B12dmJX4kxx84dF0_PqJ5XCY4iaGt7O"><i class="ti-import"></i>
                      Manual for Microchip LoRa Mote</a> -->
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>RAK Wireless</h4>
                    <h5>[Starter Kit]</h5>
                    <br />
                    <!-- <button class="verify-button" (click)="openImgModel('emone')"><i class="ti-check"></i> Verified</button> -->
                  </td>
                  <td style="text-align: center">
                    <img id="img-RAK811" src="./assets/img/RAK811.jpg" />
                  </td>
                  <td>
                    <h5>RAK811 Breakout Board</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Programmable</li>
                      <li>LoRa Modem (AT Command)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>RAK811 LoRa Module</li>
                      <li>Small size and low power</li>
                      <li>
                        Up to 15 km coverage at suburban and up to 5 km coverage
                        at urban area
                      </li>
                    </ul>
                  </td>
                  <td>
                    <!-- <a target="_blank" href="https://drive.google.com/open?id=1-B12dmJX4kxx84dF0_PqJ5XCY4iaGt7O"><i class="ti-import"></i>
                      Manual for Microchip LoRa Mote</a> -->
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>RAK Wireless</h4>
                    <h5>[Module]</h5>
                    <br />
                    <!-- <button class="verify-button" (click)="openImgModel('emone')"><i class="ti-check"></i> Verified</button> -->
                  </td>
                  <td style="text-align: center">
                    <img id="img-RAK811" src="./assets/img/RAK811Module.jpg" />
                  </td>
                  <td>
                    <h5>LoRa Module RAK811</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Programmable</li>
                      <li>LoRa Modem (AT Command)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>Small size and low power</li>
                      <li>High Receiver Sensitivity: down to -146 dBm</li>
                      <li>
                        TX Power: adjustable up to +14 dBm high efficiency PA,
                        max PA boost up to 20dbm
                      </li>
                      <li>FSK, GFSK, and LoRa Technology modulation</li>
                      <li>IIP3 = -11 dBm</li>
                      <li>
                        Up to 15 km coverage at suburban and up to 5 km coverage
                        at urban area
                      </li>
                    </ul>
                  </td>
                  <td>
                    <!-- <a target="_blank" href="https://drive.google.com/open?id=1-B12dmJX4kxx84dF0_PqJ5XCY4iaGt7O"><i class="ti-import"></i>
                      Manual for Microchip LoRa Mote</a> -->
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Seeed Studio</h4>
                    <h5>[Starter Kit]</h5>
                    <br />
                    <!-- <button class="verify-button" (click)="openImgModel('emone')"><i class="ti-check"></i> Verified</button> -->
                  </td>
                  <td style="text-align: center">
                    <img id="img-Seeeduino" src="./assets/img/Seeeduino.jpg" />
                  </td>
                  <td>
                    <h5>Seeeduino</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Programmable (Arduino Programming)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>Main MCU: ATSAMD21G18 @ 48MHz with 3.3V</li>
                      <li>
                        Arduino compatible (based on Arduino Zero bootloader)
                      </li>
                      <li>
                        Embedded with lithium battery management chip and status
                        indicator led
                      </li>
                      <li>
                        20 GPIOs, 4 on-board Grove connectors and 18 PWM pins
                      </li>
                      <li>6 analog inputs, 1 analog output (A0)</li>
                      <li>3.3V regulator with 200mA output</li>
                      <li>
                        High link budget of 160dB. -140dBm sensitivity and 19dBm
                        Output power
                      </li>
                      <li>Support LoRaWAN protocol, Class A/C</li>
                    </ul>
                  </td>
                  <td>
                    <!-- <a target="_blank" href="https://drive.google.com/open?id=1-B12dmJX4kxx84dF0_PqJ5XCY4iaGt7O"><i class="ti-import"></i>
                      Manual for Microchip LoRa Mote</a> -->
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Dragino Technology</h4>
                    <h5>[Arduino Shield]</h5>
                    <br />
                    <!-- <button class="verify-button" (click)="openImgModel('emone')"><i class="ti-check"></i> Verified</button> -->
                  </td>
                  <td style="text-align: center">
                    <img id="img-Dragino" src="./assets/img/dragino.jpg" />
                  </td>
                  <td>
                    <h5>Dragino LoRa Shield</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>LoRa Modem (Command via SPI)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>Compatible with Arduino Leonardo, Uno, Mega, DUE</li>
                      <li>External Antenna via I-Pex connector</li>
                      <li>RFM95 LoRa Module</li>
                      <li>+20 dBm - 100 mW constant RF output vs. V supply</li>
                      <li>+14 dBm high efficiency PA.</li>
                    </ul>
                  </td>
                  <td>
                    <!-- <a target="_blank" href="https://drive.google.com/open?id=1-B12dmJX4kxx84dF0_PqJ5XCY4iaGt7O"><i class="ti-import"></i>
                      Manual for Microchip LoRa Mote</a> -->
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Dragino Technology</h4>
                    <h5>[Raspberry Pi HAT]</h5>
                    <br />
                    <!-- <button class="verify-button" (click)="openImgModel('emone')"><i class="ti-check"></i> Verified</button> -->
                  </td>
                  <td style="text-align: center">
                    <img
                      id="img-DraginoHat"
                      src="./assets/img/draginoHat.jpg"
                    />
                  </td>
                  <td>
                    <h5>Dragino LoRa/GPS HAT</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Programmable</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>Compatible with Raspberry Pi 2 Model B, 3.</li>
                      <li>Support DGPS, SBAS(WAAS/EGNOS/MSAS/GAGAN)</li>
                      <li>
                        GPS automatic switching between internal patch antenna
                        and external active antenna
                      </li>
                      <li>RFM95 LoRa Module</li>
                      <li>+20 dBm - 100 mW constant RF output vs. V supply</li>
                      <li>+14 dBm high efficiency PA.</li>
                    </ul>
                  </td>
                  <td>
                    <!-- <a target="_blank" href="https://drive.google.com/open?id=1-B12dmJX4kxx84dF0_PqJ5XCY4iaGt7O"><i class="ti-import"></i>
                      Manual for Microchip LoRa Mote</a> -->
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Micro4you.com</h4>
                    <h5>[Arduino Shield]</h5>
                    <br />
                    <!-- <button class="verify-button" (click)="openImgModel('stm')"><i class="ti-check"></i> Verified</button> -->
                  </td>
                  <td style="text-align: center">
                    <img
                      id="img-Micro4youRFM95"
                      src="./assets/img/Micro4youRFM95.jpg"
                    />
                  </td>
                  <td>
                    <h5>LoRa RFM95 Shield</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>LoRa Modem (Command via SPI)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>RFM95 LoRa Module</li>
                      <li>+20 dBm - 100 mW constant RF output vs. V supply</li>
                      <li>+14 dBm high efficiency PA.</li>
                      <li>Arduino Shied and useful connector</li>
                    </ul>
                  </td>
                  <td>
                    <!-- <a target="_blank" href="https://drive.google.com/open?id=1-B12dmJX4kxx84dF0_PqJ5XCY4iaGt7O"><i
                        class="ti-import"></i>
                      Manual for Microchip RN2903</a> -->
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Hope RF</h4>
                    <h5>[Module]</h5>
                    <br />
                    <!-- <button class="verify-button" (click)="openImgModel('stm')"><i class="ti-check"></i> Verified</button> -->
                  </td>
                  <td style="text-align: center">
                    <img
                      id="img-RFM95Module"
                      src="./assets/img/RFM95Module.jpg"
                    />
                  </td>
                  <td>
                    <h5>RFM95</h5>
                    <br />
                    <h5><u>Operation Mode:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>LoRa Modem (Command via SPI)</li>
                    </ul>
                    <br />
                    <h5><u>Feature:</u></h5>
                    <ul style="list-style-type: disc">
                      <li>Support AS923 Frequency 920-925</li>
                      <li>+20 dBm - 100 mW constant RF output vs. V supply</li>
                      <li>+14 dBm high efficiency PA.</li>
                      <li>Programmable bit rate up to 300 kbps.</li>
                      <li>High sensitivity: down to -148 dBm.</li>
                      <li>
                        Low RX current of 10.3 mA, 200 nA register retention.
                      </li>
                      <li>
                        Fully integrated synthesizer with a resolution of 61 Hz.
                      </li>
                      <li>Built-in bit synchronizer for clock recovery</li>
                      <li>
                        Built-in temperature sensor and low battery indicator
                      </li>
                      <li>Modue Size：16*16 mm</li>
                    </ul>
                  </td>
                  <td>
                    <!-- <a target="_blank" href="https://drive.google.com/open?id=1-B12dmJX4kxx84dF0_PqJ5XCY4iaGt7O"><i
                        class="ti-import"></i>
                      Manual for Microchip RN2903</a> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        id="verified-text"
        class="modal-img-verify"
        (click)="closeImgModel()"
      >
        <span class="close" style="margin-top: 50px" (click)="closeImgModel()"
          >&times;</span
        >
        <img class="modal-img-verify-content" id="img-ver-text" />
        <div id="img-ver-caption"></div>
      </div>
    </div>
  </div>
</section>

<div class="content visible-md-block visible-lg-block">
  <div class="corner-ribbon bottom-right sticky green shadow">
    <a class="banner" [routerLink]="['/elearning']">E - LEARNING</a>
  </div>
</div>
