import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProvidersService = /** @class */ (function () {
    function ProvidersService(http) {
        this.http = http;
        this.rootUrl = 'https://lora.ntdigitalsolutions.com/portal';
    }
    ProvidersService.prototype.serviceRequest = function (requestobject) {
        var reqHeader = new HttpHeaders({
            'Authorization': 'Bearer ' + localStorage.getItem('userToken-admin'),
            'Content-Type': 'application/json; charset=utf-8'
        });
        switch (requestobject.method) {
            case 'GET':
                return this.http.get(this.rootUrl + requestobject.url, { headers: reqHeader });
            case 'POST':
                return this.http.post(this.rootUrl + requestobject.url, requestobject.data, { headers: reqHeader });
            case 'PUT':
                return this.http.put(this.rootUrl + requestobject.url, requestobject.data, { headers: reqHeader });
            case 'DELETE':
                return this.http.delete(this.rootUrl + requestobject.url, { headers: reqHeader });
            default:
                break;
        }
    };
    ProvidersService.ngInjectableDef = i0.defineInjectable({ factory: function ProvidersService_Factory() { return new ProvidersService(i0.inject(i1.HttpClient)); }, token: ProvidersService, providedIn: "root" });
    return ProvidersService;
}());
export { ProvidersService };
