import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';

import { ScrollToModule } from 'ng2-scroll-to-el';

import { AgmCoreModule } from '@agm/core';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';

import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';

import { AmChartsModule } from "@amcharts/amcharts3-angular";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatTreeModule } from '@angular/material/tree';
// import { AosToken, aos } from 'aos';

import { Ng2CarouselamosModule } from 'ng2-carouselamos';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { EmbedVideo } from 'ngx-embed-video';

// Multi language
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { DragScrollModule } from 'ngx-drag-scroll';

import { ProvidersService } from './services/providers.service';
import { PubliccallService } from './services/publiccall.service';
import { TranslationService } from './services/translation.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { FaqComponent } from './components/faq/faq.component';
import { PartnerComponent } from './components/partner/partner.component';
import { CoverageComponent } from './components/coverage/coverage.component';
import { UseCaseComponent } from './components/use-case/use-case.component';
import { DownloadComponent } from './components/download/download.component';
import { PolicyComponent } from './components/policy/policy.component';
import { FooterComponent } from './components/footer/footer.component';
import { NewsEventComponent } from './components/news-event/news-event.component';
import { ElearningComponent } from './components/elearning/elearning.component';

import { TranslatePipe } from './pipes/translate.pipe';
import { TranslateDirective } from './directives/translate.directive';

//* Import toast module    
import { ToastrModule } from 'ng6-toastr-notifications';

const appRoutes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: 'home', component: HomeComponent
  },
  {
    path: 'faq', component: FaqComponent
  },
  {
    path: 'partner', component: PartnerComponent
  },
  {
    path: 'coverage', component: CoverageComponent
  },
  {
    path: 'usecase', component: UseCaseComponent
  },
  {
    path: 'events', component: NewsEventComponent
  },
  {
    path: 'download', component: DownloadComponent
  },
  {
    path: 'policy', component: PolicyComponent
  },
  {
    path: 'elearning', component: ElearningComponent
  }
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FaqComponent,
    PartnerComponent,
    CoverageComponent,
    UseCaseComponent,
    DownloadComponent,
    PolicyComponent,
    FooterComponent,
    NewsEventComponent,
    ElearningComponent,
    TranslatePipe,
    TranslateDirective,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    ScrollToModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCPNrOv8DYD9d9Dc4i7WATJCTD2hvCZ-Oc',
      libraries: ["places"]
    }),
    // AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AnimateOnScrollModule.forRoot(),
    AmChartsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    MatTreeModule,
    Ng2CarouselamosModule,
    EmbedVideo.forRoot(),
    HttpModule,
    DragScrollModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    ProvidersService,
    PubliccallService,
    TranslationService], //{ provide: AosToken, useValue: aos }
  bootstrap: [AppComponent]
})
export class AppModule { }
