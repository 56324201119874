import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ScrollToService } from 'ng2-scroll-to-el';

import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-news-event',
  templateUrl: './news-event.component.html',
  styleUrls: ['./news-event.component.css']
})
export class NewsEventComponent implements OnInit {
  language: any;

  eventLst: any;

  show = false;

  constructor(
    private router: Router,
    private scrollService: ScrollToService,
    private translator: TranslationService) {


    // this.translate = translate;
    // this.language = localStorage.getItem('lang')
    // this.translate.setDefaultLang(this.language);

    // console.warn("localStorage EVENT:", this.language);


    // if (this.language != null) {
    //   this.translate.use(this.language);
    // }
    // else {
    //   localStorage.setItem('lang', 'th');
    //   this.translate.setDefaultLang(this.language);
    // }


    this.eventLst = [{
      show: false,
      image: 'assets/img/event18.jpg',
      postTags: ['LoRa', 'Workshop', 'NIA', 'TESA'],
      title: 'EVENT_TITLE_18',
      postDate: 'EVENT_DATE_18',
      content: 'EVENT_CONTENT_18'
    },{
      show: false,
      image: 'assets/img/event17.jpg',
      postTags: ['LoRa', 'Smart Waste', 'Phuket', 'Smart City'],
      title: 'EVENT_TITLE_17',
      postDate: 'EVENT_DATE_17',
      content: 'EVENT_CONTENT_17'
    },
    {
      show: false,
      image: 'assets/img/event16.jpg',
      postTags: ['LoRa', 'Workshop', 'MOU', 'Chula', 'PM2.5'],
      title: 'EVENT_TITLE_16',
      postDate: 'EVENT_DATE_16',
      content: 'EVENT_CONTENT_16'
    },
    {
      show: false,
      image: 'assets/img/event15.jpg',
      postTags: ['LoRa', 'Workshop', 'NIA', 'TESA'],
      title: 'EVENT_TITLE_15',
      postDate: 'EVENT_DATE_15',
      content: 'EVENT_CONTENT_15'
    },
    {
      show: false,
      image: 'assets/img/event1.jpg',
      postTags: ['LoRa', 'Workshop'],
      title: 'EVENT_TITLE_14',
      postDate: 'EVENT_DATE_14',
      content: 'EVENT_CONTENT_14'
    },
    {
      show: false,
      image: 'assets/img/event2.jpg',
      postTags: ['LoRa', 'Show case'],
      title: 'EVENT_TITLE_13',
      postDate: 'EVENT_DATE_13',
      content: 'EVENT_CONTENT_13'
    },
    {
      show: false,
      image: 'assets/img/event3.jpg',
      postTags: ['LoRa', 'Seminar'],
      title: 'EVENT_TITLE_12',
      postDate: 'EVENT_DATE_12',
      content: 'EVENT_CONTENT_12'
    },
    {
      show: false,
      image: 'assets/img/event4.jpg',
      postTags: ['IoT', 'Innovation', 'Big Data', 'Cloud Computing'],
      title: 'EVENT_TITLE_11',
      postDate: 'EVENT_DATE_11',
      content: 'EVENT_CONTENT_11'
    },
    {
      show: false,
      image: 'assets/img/event5.jpg',
      postTags: ['LoRa', 'Seminar'],
      title: 'EVENT_TITLE_10',
      postDate: 'EVENT_DATE_10',
      content: 'EVENT_CONTENT_10'
    },
    {
      show: false,
      image: 'assets/img/event6.jpg',
      postTags: ['IoT', 'MOU', 'LPWAN', 'Cloud Computing'],
      title: 'EVENT_TITLE_9',
      postDate: 'EVENT_DATE_9',
      content: 'EVENT_CONTENT_9'
    },
    {
      show: false,
      image: 'assets/img/event7.jpg',
      postTags: ['LoRa', 'Show case'],
      title: 'EVENT_TITLE_8',
      postDate: 'EVENT_DATE_8',
      content: 'EVENT_CONTENT_8'
    },
    {
      show: false,
      image: 'assets/img/event8.jpg',
      postTags: ['LoRa', 'Phuket', 'Smart City'],
      title: 'EVENT_TITLE_7',
      postDate: 'EVENT_DATE_7',
      content: 'EVENT_CONTENT_7'
    },
    {
      show: false,
      image: 'assets/img/event9.jpg',
      postTags: ['IoT', 'Show Case'],
      title: 'EVENT_TITLE_6',
      postDate: 'EVENT_DATE_6',
      content: 'EVENT_CONTENT_6'
    },
    {
      show: false,
      image: 'assets/img/event10.jpg',
      postTags: ['LoRa', 'Workshop', 'NIA', 'TESA', 'TRF'],
      title: 'EVENT_TITLE_5',
      postDate: 'EVENT_DATE_5',
      content: 'EVENT_CONTENT_5'
    },
    {
      show: false,
      image: 'assets/img/event11.jpg',
      postTags: ['LoRa', 'Workshop', 'NIA', 'TESA', 'TRF', 'Developers'],
      title: 'EVENT_TITLE_4',
      postDate: 'EVENT_DATE_4',
      content: 'EVENT_CONTENT_4'
    },
    {
      show: false,
      image: 'assets/img/event12.jpg',
      postTags: ['LoRa', 'Workshop', 'NIA', 'TESA', 'TRF', 'Developers'],
      title: 'EVENT_TITLE_3',
      postDate: 'EVENT_DATE_3',
      content: "EVENT_CONTENT_3"
    },
    {
      show: false,
      image: 'assets/img/event13.jpg',
      postTags: ['LoRa', 'MOU', 'LPWAN', 'Cloud Computing'],
      title: 'EVENT_TITLE_2',
      postDate: 'EVENT_DATE_2',
      content: "EVENT_CONTENT_2",
    },
    {
      show: false,
      image: 'assets/img/event14.jpg',
      postTags: ['LoRa', 'Workshop'],
      title: 'EVENT_TITLE_1',
      postDate: 'EVENT_DATE_1',
      content: "EVENT_CONTENT_1",
    },
    ]
  }

  // changeLanguage(lang: string) {
  //   console.log("lang:", lang);

  //   this.translate.use(lang);
  //   localStorage.setItem('lang', lang);
  //   this.language = lang;
  // }

  changeLanguage(lang: string) {
      // console.log("lang EVENT :", lang);
    this.translator.setLanguage(lang);
    this.language = lang;
  }

  ngOnInit() {
    this.scrollService.scrollTo("#newsevent-area-id", 0, 0);
  }

  // goToSection(gotoSection) : void {
  //   switch (gotoSection) {
  //     case "info":
  //       localStorage.setItem("gotoSection", "info");
  //     break;
  //     case "feature":
  //       localStorage.setItem("gotoSection", "feature");
  //     break;
  //     case "aboutus":
  //       localStorage.setItem("gotoSection", "aboutus");
  //     break;
  //     default:
  //       break;
  //   }
  //   this.router.navigate(["/home"]);
  // }
}
