import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PubliccallService = /** @class */ (function () {
    function PubliccallService(http) {
        this.http = http;
        // readonly rootUrl = 'http://122.155.223.60';
        // readonly rootUrl = 'https://loraiot.cattelecom.com' + this.dataService.rootUrl;
        this.rootUrl = 'https://lora.ntdigitalsolutions.com/portal';
        this.reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    }
    PubliccallService.prototype.userAuthentication = function (userName, password) {
        var data = {
            "username": userName,
            "password": password
        };
        return this.http.post(this.rootUrl + '/iotapi/auth/token', data, { headers: this.reqHeader });
    };
    PubliccallService.prototype.getUserInfo = function () {
        // return this.http.get(this.rootUrl + '/iotapi/core/userInfo', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('userToken') }) });
        return this.http.get(this.rootUrl + '/iotapi/core/userInfo');
    };
    PubliccallService.prototype.getSiteLoRa = function (lng, lat) {
        // console.warn(this.rootUrl + '/iotapi/core/baseStations/range?lat=' + lat.toFixed(6) + '&lon=' + lng.toFixed(6));
        return this.http.get(this.rootUrl + '/iotapi/core/baseStations/range?lat=' + lat.toFixed(6) + '&lon=' + lng.toFixed(6), { headers: this.reqHeader });
    };
    PubliccallService.prototype.serviceRequest = function (requestobject) {
        var headerReq;
        if (requestobject.requireCaptcha) {
            headerReq = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True', 'g-recaptcha-response': requestobject.captchaResponse });
        }
        else {
            headerReq = this.reqHeader;
        }
        switch (requestobject.method) {
            case 'GET':
                return this.http.get(this.rootUrl + requestobject.url, { headers: headerReq });
            case 'POST':
                return this.http.post(this.rootUrl + requestobject.url, requestobject.data, { headers: headerReq });
            case 'PUT':
                return this.http.put(this.rootUrl + requestobject.url, requestobject.data, { headers: headerReq });
            case 'DELETE':
                return this.http.delete(this.rootUrl + requestobject.url, { headers: headerReq });
            default:
                break;
        }
    };
    PubliccallService.ngInjectableDef = i0.defineInjectable({ factory: function PubliccallService_Factory() { return new PubliccallService(i0.inject(i1.HttpClient)); }, token: PubliccallService, providedIn: "root" });
    return PubliccallService;
}());
export { PubliccallService };
