import { Component, OnInit } from '@angular/core';
import { ScrollToService } from 'ng2-scroll-to-el';

import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {
  language: any;

  constructor(private scrollService: ScrollToService,
    private translator: TranslationService) {


    // this.translate = translate;
    // this.language = localStorage.getItem('lang')
    // this.translate.setDefaultLang(this.language);

    // console.warn("localStorage DOWNLOAD:", this.language);


    // if (this.language != null) {
    //   this.translate.use(this.language);
    // }
    // else {
    //   localStorage.setItem('lang', 'th');
    //   this.translate.setDefaultLang(this.language);
    // }
  }

  //  changeLanguage(lang: string) {
  //   console.log("lang:", lang);

  //   this.translate.use(lang);
  //   localStorage.setItem('lang', lang);
  //   this.language = lang;
  // }


  changeLanguage(lang: string) {
    // console.log("lang DOWNLOAD :", lang);
    this.translator.setLanguage(lang);
    this.language = lang;
  }

  ngOnInit() {
    document.getElementById("docSupport").style.display = "block";
    this.scrollService.scrollTo("#download-area-id", 0, 0);
  }

  tabSelect(evt, tabid) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabid).style.display = "block";
    evt.currentTarget.className += " active";
  }

  testClick() {
    // console.log("test");

    document.getElementById("dropdownSupport").style.height = "100px";
  }

  openImgModel(imgshow) {
    // alert();
    // Get the modal
    var modal = document.getElementById('verified-text');

    // Get the image and insert it inside the modal - use its "alt" text as a caption
    // img = document.getElementById('myImg');
    var modalImg = document.getElementById("img-ver-text") as HTMLImageElement;
    // captionText = document.getElementById("caption") as HTMLImageElement;
    // span = document.getElementsByClassName("close")[0];
    modal.style.display = "block";

    switch (imgshow) {
      case 'stm':
        modalImg.src = './assets/img/stm-verify.jpg';
        break;
      case 'emone':
        modalImg.src = './assets/img/iM980A-verify.jpg';
        break;
      case 'gravitech':
        modalImg.src = './assets/img/gravitech-verify.jpg';
        break;
      default:
        break;
    }
    // this.captionText.innerHTML = 'พื้นที่ครอบคลุมของสัญญาณ';
  }

  closeImgModel() {
    var modal = document.getElementById('verified-text');
    modal.style.display = "none";
  }
}
