<!-- start banner Area -->
<header id="header-sec" class="header-sec-scrolled">
  <div class="container">
    <div class="row align-items-center justify-content-between d-flex">
      <div id="logo">
        <a href="index.html">
          <img id="img-logo" src="./assets/img/logo.png" alt="" title="" />
        </a>
      </div>
      <nav id="nav-menu-container">
        <ul class="nav-menu">
          <li class="menu-active">
            <a href="javascript:void(0)" [scrollTo]="'#home'">Home</a>
          </li>
          <!-- <li>
              <a class="hidden-sm-down" href="javascript:void(0)" [scrollTo]="'#coverage-area-id'"
                [scrollOffset]="-50">Info</a>
              <a class="hidden-md-up" href="#coverage-area-id">Info</a>
            </li> -->
          <!-- <li>
                <a href="#coverage-area-id" >Info</a>
              </li> -->
          <li>
            <a
              class="hidden-sm-down"
              href="javascript:void(0)"
              [scrollTo]="'#features'"
              >Features</a
            >
            <a class="hidden-md-up" href="#features">Features</a>
          </li>
          <li>
            <a
              class="hidden-sm-down"
              href="javascript:void(0)"
              [scrollTo]="'#map-coverage-area-id'"
              >Coverage Area</a
            >
            <!--  (click)="goToCoverage()" -->
            <a class="hidden-md-up" href="#map-coverage-area-id"
              >Coverage Area</a
            >
          </li>
          <li>
            <a
              class="hidden-sm-down"
              href="javascript:void(0)"
              [scrollTo]="'#products'"
              >Connectivity</a
            >
            <a class="hidden-md-up" href="#products">Connectivity</a>
          </li>
          <li>
            <a [routerLink]="['/events']">News & Events</a>
          </li>
          <li>
            <a [routerLink]="['/elearning']">E-Learning</a>
          </li>
          <li>
            <!-- <a href="javascript:void(0)" [scrollTo]="'#event'">News & Events</a> -->
            <a [routerLink]="['/usecase']">Use Cases</a>
          </li>
          <!-- <li>
            <a class="hidden-sm-down" href="javascript:void(0)" [scrollTo]="'#aboutus'">About Us</a>
            <a class="hidden-md-up" href="#aboutus">About Us</a>
          </li> -->
          <li>
            <br /><!-- <a [routerLink]="['/home']" [queryParams]="{ section: 'aboutus' }">About us</a> -->
          </li>
          <li class="hidden-md-up">
            <a href="javascript:void(0)">Support</a>
          </li>
          <li class="hidden-md-up">
            <a [routerLink]="['/faq']">&nbsp;&nbsp;> FAQ</a>
          </li>
          <li class="hidden-md-up">
            <a [routerLink]="['/download']">&nbsp;&nbsp;> LoRa Document</a>
          </li>
          <li class="hidden-sm-down">
            <div class="dropdown">
              <a href="javascript:void(0)">Support</a>
              <div class="dropdown-content">
                <a [routerLink]="['/faq']">FAQ</a>
                <a [routerLink]="['/download']"> LoRa Document</a>
              </div>
            </div>
          </li>

          <!-- <li class="menu-has-children">
                      <a href="">Pages</a>
                      <ul>
                        <li>
                          <a href="generic.html">Generic</a>
                        </li>
                        <li>
                          <a href="elements.html">Elements</a>
                        </li>
                      </ul>
                    </li> -->
        </ul>
      </nav>
      <!-- #nav-menu-container -->
      <div id="language" class="lang-btn">
        <a href="javascript:void(0)" (click)="changeLanguage('en')">
          <img
            [ngClass]="{ 'img-grey': this.language == 'th' }"
            id="img-lang"
            src="./assets/img/en.png"
            alt=""
            title=""
        /></a>
        <a href="javascript:void(0)" (click)="changeLanguage('th')"
          ><img
            [ngClass]="{ 'img-grey': this.language == 'en' }"
            id="img-lang"
            src="./assets/img/th.png"
            alt=""
            title=""
        /></a>
      </div>
    </div>
  </div>
</header>

<!-- <section class="banner-area" id="home" style="background-image: url('./assets/img/header-bg2.jpg'); background-repeat: no-repeat; background-size: cover;">
  <br>

  <div class="container">
    <div style="height: 80px;"></div>
    <div class="row fullscreen d-flex align-items-center justify-content-start">

      <div class="banner-content col-lg-7">
        <h6 class="text-white text-uppercase">Now you can feel the Energy</h6>
        <h1>
          Internet of Things Long Range IoT </h1>
        <br>
        <h2>by CAT Telecom PCL</h2><img id="img-cat-logo" src="./assets/img/cat-telecom-logo.png" alt="" title="" data-aos="zoom-in"
        />
        <a href="#" class="primary-btn text-uppercase">Buy Now</a>
      </div>
    </div>
  </div>
</section> -->
<!-- End banner Area -->

<!-- Start info Area -->
<!-- <section class="video-sec-area vid-area pb-100 pt-40" id="info">
  <div class="container">
    <div class="row" style="height: 10vw;"></div>
    <div class="row justify-content-start align-items-center">
      <div class="col-lg-6 video-right justify-content-center align-items-center d-flex">
        <div class="overlay overlay-bg"></div>
        <a class="play-btn" href="https://www.youtube.com/watch?v=ARA0AxrnHdM">
          <img class="img-fluid" src="./assets/img/play-icon1.png" alt="">
        </a>
      </div>
      <div class="col-lg-6 video-left">
        <h6>Live Coffee making process.</h6>
        <h1>We Telecast our <br> Coffee Making Live</h1>
        <h1>LoRa คืออะไร</h1>
        <p>
          <span>We are here to listen from you deliver exellence</span>
        </p>
        <p class="font-para-16 p-justify">
          LoRa เป็นเทคโนโลยีการเชื่อมต่อไร้สายเหมาะสำหรับงาน Internet of Thing (IoT)
          ที่ข้อมูลหลักๆมาจากเซ็นเซอร์รับข้อมูลต่างๆ โดยเป็นเทคโนโลยีที่มีการใช้งานแพร่หลาย
          บริษัทชั้นนำผู้พัฒนาไมโครคอนโทรลเลอร์พัฒนาอุปกรณ์มารองรับการเชื่อมต่อ LoRa เทคโนโลยี LoRa
          มีจุดเด่นในเรื่องระยะทางในการสื่อสาร
          สามารถรับ-ส่งข้อมูลได้ในระยะไกล 5-15 กิโลเมตร ประหยัดพลังงานจากการใช้พลังงานในช่วงรับ-ส่งข้อมูลค่อนข้างต่ำ
          ในช่วงที่ไม่ส่งข้อมูล
          สามารถกำหนดให้อุปกรณ์เข้าสู่โหมดประหยัดพลังงานได้ ทำให้ประหยัดค่าใช้จ่าย
        </p>
        <img class="img-fluid" src="./assets/img/signature.png" alt="">
      </div>
    </div>
  </div>
</section> -->
<!-- End info Area -->

<section class="banner-cus-area" id="home">
  <div class="container">
    <div style="height: 200px"></div>
    <div class="row">
      <div class="col-md-10 col-md-offset-1">
        <h1 style="color: #ffffff; font-size: calc(35px + 1vw)">
          Internet of Things
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 col-md-offset-1">
        <h1 style="color: #ffffff; font-size: calc(35px + 1vw)">
          Long Range IoT
        </h1>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div class="row">
      <div class="col-md-10 col-md-offset-1">
        <h1 style="color: #ffffff; font-size: calc(25px + 1vw)">
          by National Telecom
        </h1>
      </div>
    </div>
    <br />
    <div>
      <img
        id="img-cat-logo"
        src="./assets/img/national-telecom-logo.png"
        alt=""
        title=""
        data-aos="zoom-in"
      />
    </div>
  </div>
  <!-- <br>
        <h1 style="color: #ffffff">Long Range IoT </h1>
        <br>
        <h2>by CAT Telecom PCL</h2> -->
</section>

<!-- coverage Area -->
<section
  class="coverage-area"
  id="coverage-area-id"
  style="
    background-image: url('./assets/img/site-tower2.png');
    background-repeat: no-repeat;
    background-size: cover;
  "
>
  <div class="container">
    <div class="row" style="height: 18vw"></div>
    <div class="row">
      <div class="col-0 col-md-5 content-image-center">
        <!-- <img id="img-logo" src="./assets/img/site-tower2.png" alt="" title="" data-aos="zoom-in" />  -->
      </div>
      <div class="col-12 col-md-7 content-align-center">
        <div>
          <h1 data-aos="fade-left" style="color: #ffd004">
            {{ "TITLE_1" | translate }}
          </h1>
          <br />
          <div data-aos="fade-up">
            <p class="font-para-24 p-justify">
              <!-- LoRa เป็นเทคโนโลยีการเชื่อมต่อไร้สายเหมาะสำหรับงาน Internet of Things
              (IoT) มีจุดเด่นในเรื่องของระยะทางในการสื่อสาร สามารถรับ-ส่งข้อมูลได้ในระยะไกล 5-15 กิโลเมตร
              และเนื่องจากพลังงานที่ใช้ในช่วงรับ-ส่งข้อมูลค่อนข้างต่ำ
              อีกทั้งยังสามารถกำหนดให้อุปกรณ์เข้าสู่โหมดประหยัดพลังงานในกรณีที่ไม่ได้ส่งข้อมูล จึงทำให้ LoRa
              เป็นเทคโนโลยีที่ช่วยประหยัดพลังงานและค่าใช้จ่ายในการใช้งาน -->
              {{ "INFO" | translate }}
            </p>
            <!-- <p class="font-para-20">การตอบสนองที่รวดเร็วในการสือสารด้วยโครงข่ายแบบ Star Network</p> -->
          </div>

          <div style="text-align: center">
            <a
              class="play-btn"
              href="https://www.youtube.com/watch?v=ZOw7o_9VmKs"
            >
              {{ "VIDEO" | translate }}&nbsp;&nbsp;&nbsp;<img
                id="img-playvid"
                src="./assets/img/play-icon2.png"
                alt=""
              />
            </a>
            <!-- <p class="font-para-20 p-justify">ระยะสัญญาณที่ไกลโดยเฉลี่ย 5 - 10 กิโลเมตร
              ลดข้อจำกัดของระยะทางในการส่งข้อมูลของอุปกรณ์</p>
            <p class="font-para-20">การตอบสนองที่รวดเร็วในการสือสารด้วยโครงข่ายแบบ Star Network</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="hidden-sm-up"
    style="
      height: 100vh;
      width: 100wv;
      background-color: black;
      margin-top: -100vh;
    "
  ></div>
</section>
<!-- End coverage Area -->

<!-- diagram Area -->
<section class="diagram-area" id="diagram-area-id">
  <div class="container">
    <div class="row" style="height: 5vw"></div>
    <div class="col-md-12" data-aos="fade-left" style="text-align: center">
      <h1 style="color: #ffd004">{{ "TITLE_2" | translate }}</h1>
      <p>{{ "SUB_TITLE_2" | translate }}</p>
    </div>
    <div class="row hidden-xs-down">
      <div class="col-md-12 content-image-center">
        <img
          id="img-lora-diagram"
          src="./assets/img/lora-network.png"
          alt=""
          title=""
          data-aos="zoom-in"
        />
      </div>
    </div>
    <div class="row hidden-sm-up">
      <div class="col-md-12 content-image-center">
        <img
          id="img-lora-diagram-v"
          src="./assets/img/lora-network-v.png"
          alt=""
          title=""
          data-aos="zoom-in"
        />
      </div>
    </div>
  </div>
</section>
<!-- End coverage Area -->

<!-- power Area -->
<!-- <section class="power-area" id="power-area-id">
  <div class="container">
    <div class="row" style="height: 20vw;"></div>
    <div class="row d-flex justify-content-center">
      <h1 data-aos="fade-down" style="color: #FFFFFF;">Low Power</h1>
    </div>
    <br><br>
    <div class="row d-flex justify-content-center">
      <div class="hide-on-init" animateOnScroll animationName="animated fadeIn" offset="300">
        <p class="font-para-20">มีการจัดการพลังงานของอุปกรณ์ที่ดีส่งผลให้อายุการใช้งานของ Battery ยาวนาน</p>
      </div>
    </div>
    <br><br>
    <div class="row d-flex justify-content-center">
      <div class="col-md-2"></div>
      <div class="col-md-3">
        <img id="img-battery" src="./assets/img/img-battery.png" alt="" title="" data-aos="zoom-in" />
      </div>
      <div class="col-md-6 justify-content-center">
        <br>
        <p>คำนวณจากการส่งข้อมูล 1-2 ครั้ง/ชั้วโมง</p>
        <div id="bar-chart"></div>
        <br>
        <div data-aos="fade-up">
          <h2 style="color: rgb(145, 255, 94);">10+ years</h2>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>

    <div class="row d-flex justify-content-center">
      <img id="img-powerß" src="./assets/img/low-power.jpg" alt="" title="" data-aos="zoom-in" />
      <i class="fa fa-battery-full fa-5x" aria-hidden="true" style="color: #FFFFFF;"></i>
    </div>
    <br>
    <br>
        
    <div class="row d-flex justify-content-center">
      
    </div>
  </div>
</section> -->
<!-- End power Area -->

<!-- Cost Area -->
<!-- <section class="cost-area" id="cost-area-id" style="background-image: url('./assets/img/low-cost-bg2.png');">
  style="background-image: url('./assets/img/low-cost-bg.png');"
  <div class="container">
    <div class="row" style="height: 20vw;"></div>
    <div class="row">
      <div class="col-md-5 feature-box content-align-center">
        <h1 data-aos="fade-left" style="color: #FFFFFF;">Low Cost</h1>
        <br>
        <div data-aos="fade-up">
          <p class="font-para-20">ลดค่าใช้จ่ายในการลงทุนโครงสร้างพื้นฐาน และประหยัดต้นทุนในการเปลี่ยนถ่าย Battery </p>
          <p class="font-para-20">Unlicensed band 920-925 MHz (AS-923)</p>
        </div>
      </div>
      <div class="col-md-5 feature-box content-image-center">
        <div class="row">
          <div class="col-6 col-md-6" data-aos="zoom-out">
            <p>ค่าใช้จ่าย Connectivity ที่ถูกลง</p>
          </div>
          <div class="col-6 col-md-6" data-aos="fade-up">
            <p>ส่งผลให้สามารถเพิ่มจำนวณอุปกรณ์ได้มากยิ่งขึ้น</p>
          </div>
        </div>

        <div class="row">
          <div class="col-6 col-md-6" data-aos="zoom-out">
            <i class="fa fa-btc fa-2x" aria-hidden="true"></i>&nbsp;&nbsp;<i class="fa fa-long-arrow-down fa-2x" aria-hidden="true"></i>
          </div>
          <div class="col-6 col-md-6" data-aos="fade-up">
            <i class="fa fa-thermometer-quarter fa-2x" aria-hidden="true"></i>&nbsp;&nbsp;<i class="fa fa-long-arrow-up fa-2x"
              aria-hidden="true"></i>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <img id="img-coin" src="./assets/img/arrow-down.png" alt="" title="" data-aos="zoom-in" />
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- End Cost Area -->

<!-- Secure Area -->
<!-- <section class="security-area" id="security-area-id" style="background-image: url('./assets/img/security-bg.png'); background-repeat: no-repeat; background-size: cover;">
  <div class="container">
    <div class="row" style="height: 20vw;"></div>
    <div class="row">
      <div class="col-md-5 feature-box content-image-center">
        <img id="img-logo" src="./assets/img/lock-AES128.png" alt="" title="" data-aos="zoom-in" />
      </div>
      <div class="col-md-5 feature-box content-align-center">
        <br><br>
        <h1 data-aos="fade-left" style="color: #FFFFFF;">High Capacity</h1>
        <br>
        <div data-aos="fade-up">
          <p class="font-para-20">รองรับการรับส่งข้อมูลมากกว่า 1 ล้านข้อความต่อ Base Station</p>
          <p class="font-para-20">ตอบสนองความต้องการของผู้ประกอบการเครือข่ายสาธารณะที่ให้บริการในตลาดขนาดใหญ่</p>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- End Secure Area -->

<!-- High Capacity Area -->
<!-- <section class="cost-area" id="test">
  <div class="container">
    <div class="row" style="height: 20vw;"></div>
    <div class="row">
      <div class="col-md-5 feature-box content-align-center">
        <br><br>
        <h1 data-aos="fade-left" style="color: #FFFFFF;">Secure</h1>
        <div id="bar-chart"></div>
        <br>
        <div data-aos="fade-up">
          <p class="font-para-20">มีการเข้ารหัส AES128 แบบ end-to-end</p>
          <p class="font-para-20">การตรวจสอบความถูกต้องซึ่งกันและกันการป้องกันความสมบูรณ์และการรักษาความลับ</p>
        </div>
        <div class="hidden-md-up" style="height: 30px;"></div>
      </div>
      <div class="col-md-5 feature-box content-image-center">
        <div class="row d-flex justify-content-center">
          <img id="img-system" src="./assets/img/img-system.png" alt="" title="" data-aos="zoom-in" />
          <img id="img-security" src="./assets/img/img-security.png" alt="" title="" data-aos="zoom-in" data-aos-delay="400" />
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- End High Capacity Area -->

<!-- Start features Area -->
<section class="blog-area section-gap" id="features">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="menu-content pb-60 col-lg-10">
        <div class="title text-center" data-aos="fade-down">
          <h1 class="mb-10 h1-color font-black">{{ "TITLE_3" | translate }}</h1>
          <p class="font-black">{{ "SUB_TITLE_3" | translate }}</p>
        </div>
      </div>
    </div>

    <!-- <input id="search-box" placeholder="search for location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
    <p></p>
    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" (mapClick)="mapClicked($event)">

      <agm-marker *ngFor="let m of markers; let i = index" (markerClick)="clickedMarker(m.label, i)" [latitude]="m.lat" [longitude]="m.lng"
        [label]="m.label" [markerDraggable]="m.draggable" (dragEnd)="markerDragEnd(m, $event)">

        <agm-info-window>
          <strong>InfoWindow content</strong>
        </agm-info-window>

      </agm-marker>
c
      <agm-kml-layer [zIndex]="9999" [url]="urlKml">

      </agm-kml-layer>

      <agm-circle [latitude]="lat + 0.3" [longitude]="lng" [radius]="5000" [fillColor]="'red'" [circleDraggable]="true" [editable]="true">
      </agm-circle>

      <agm-circle [latitude]="lat" [longitude]="lng" [radius]="10000" [fillColor]="'red'">
      </agm-circle>

    </agm-map> -->

    <div data-aos="fade-up" class="row justify-content-center">
      <div class="col-md-4 feature-box">
        <i class="fa fa-location-arrow fa-3x font-black" aria-hidden="true"></i>
        <p></p>
        <h4 class="font-black">Coverage</h4>
        <br />
        <p class="p-justify font-black">{{ "FEATURE_CV1" | translate }}</p>
        <p class="p-justify font-black">{{ "FEATURE_CV2" | translate }}</p>
      </div>

      <div class="col-md-4 feature-box">
        <i class="fa fa-battery-full fa-3x font-black" aria-hidden="true"></i>
        <p></p>
        <h4 class="font-black">Power</h4>
        <br />
        <!-- <p>Low power consumption</p>
        <p>10+ years battery lift</p> -->
        <p class="p-justify font-black">{{ "FEATURE_P1" | translate }}</p>
        <p>
          <b>
            <h4 class="font-black">{{ "FEATURE_P2" | translate }}</h4>
          </b>
        </p>
      </div>

      <div class="col-md-4 feature-box">
        <i class="fa fa-money fa-3x font-black" aria-hidden="true"></i>
        <p></p>
        <h4 class="font-black">Cost</h4>
        <br />
        <!-- <p>Unlicensed band 920-925 MHz (AS-923)</p>
        <p>Low-cost deployment</p> -->
        <p class="p-justify font-black">{{ "FEATURE_C1" | translate }}</p>
        <p class="p-justify font-black">{{ "FEATURE_C2" | translate }}</p>
      </div>

      <div class="col-md-4 feature-box">
        <i class="fa fa-cogs fa-3x font-black" aria-hidden="true"></i>
        <p></p>
        <h4 class="font-black">Usage</h4>
        <br />
        <!-- <p>Adaptive Data Rate (ADR)</p>
        <p>Network Scalability</p> -->
        <p class="p-justify font-black">{{ "FEATURE_U1" | translate }}</p>
        <p class="p-justify font-black">{{ "FEATURE_U2" | translate }}</p>
      </div>

      <div class="col-md-4 feature-box">
        <i class="fa fa-lock fa-3x font-black" aria-hidden="true"></i>
        <p></p>
        <h4 class="font-black">Secure</h4>
        <br />
        <p class="p-justify font-black">{{ "FEATURE_S1" | translate }}</p>
        <p class="p-justify font-black">{{ "FEATURE_S2" | translate }}</p>
        <p class="p-justify font-black">{{ "FEATURE_S3" | translate }}</p>
      </div>

      <div class="col-md-4 feature-box">
        <i class="fa fa-wifi fa-3x font-black" aria-hidden="true"></i>
        <p></p>
        <h4 class="font-black">Frequency</h4>
        <br />
        <p class="p-justify font-black">{{ "FEATURE_F1" | translate }}</p>
      </div>
    </div>
    <!--  <br>
    <br>
    <br>
    <br>
   <div class="row d-flex justify-content-center">
      <a class="genric-btn primary radius radius arrow e-large border" type="button" target="_blank" [routerLink]="['/usecase']">Use
        case
        <span class="lnr lnr-arrow-right"></span>
      </a>
    </div> -->
  </div>
</section>
<!-- End features Area -->

<!-- Start class Area -->
<section class="lora-class-area" id="lora-class-area-id">
  <div class="container">
    <div class="row" style="height: 5vw"></div>
    <!-- <div class="row d-flex justify-content-center">
      <div class="menu-content pb-60 col-lg-10">
        <div class="title text-center">
          <h1 class="mb-10" data-aos="fade-down">LoRa Class</h1>
          <p>Who are in extremely love with eco friendly system.</p>
        </div>
      </div>
    </div> -->

    <div class="col-md-12" data-aos="fade-left" style="text-align: center">
      <h1 style="color: #ffffff">{{ "TITLE_4" | translate }}</h1>
      <p>{{ "SUB_TITLE_4" | translate }}</p>
    </div>

    <div class="row">
      <div class="col-md-12 content-image-center">
        <img
          id="img-lora-class"
          src="./assets/img/lora-class2.png"
          alt=""
          title=""
          data-aos="zoom-in"
        />
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-5">
        <h3 style="color: #ffffff">{{ "CLASS_TITLE_A" | translate }}</h3>
        <br />
      </div>

      <div class="col-md-7">
        <p class="font-para-20 p-justify">{{ "CLASS_DESC_A" | translate }}</p>
      </div>

      <div class="col-md-5">
        <h3 style="color: #ffffff">{{ "CLASS_TITLE_B" | translate }}</h3>
        <br />
      </div>

      <div class="col-md-7">
        <p class="font-para-20 p-justify">{{ "CLASS_DESC_B" | translate }}</p>
        <!-- <p class="font-para-20 p-justify">อุปกรณ์จะรองรับการสื่อสารแบบสองทิศทางระหว่างอุปกรณ์และ Gateway
          โดยการส่งข้อความจากอุปกรณ์ไปยัง Server จะสามารถทำได้ตลอดเวลา</p> -->
      </div>

      <div class="col-md-5">
        <h3 style="color: #ffffff">{{ "CLASS_TITLE_C" | translate }}</h3>
        <br />
      </div>

      <div class="col-md-7">
        <p class="font-para-20 p-justify">{{ "CLASS_DESC_C" | translate }}</p>
      </div>
    </div>
    <div class="row" style="height: 10vh"></div>
  </div>
</section>
<!-- End class Area -->

<!-- Start activation Area -->
<section class="section-gap" id="activation">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="menu-content pb-60 col-lg-10">
        <div class="title text-center">
          <h1 class="mb-10">Activation Mode</h1>
          <!-- <p>Who are in extremely love with eco friendly system.</p> -->
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-5">
        <!-- <i class="fa fa-location-arrow fa-3x" aria-hidden="true"></i> -->
        <p></p>
        <h3>{{ "ACT_TITLE_OTAA" | translate }}</h3>
        <br />
        <p class="font-para-20 p-justify">
          {{ "ACT_DESC_OTAA_1" | translate }}
        </p>
        <p class="font-para-20 p-justify">
          {{ "ACT_DESC_OTAA_2" | translate }}
        </p>
      </div>

      <div class="col-md-5">
        <!-- <i class="fa fa-battery-full fa-3x" aria-hidden="true"></i> -->
        <p></p>
        <h3>{{ "ACT_TITLE_ABP" | translate }}</h3>
        <br />
        <p class="font-para-20 p-justify">{{ "ACT_DESC_ABP_1" | translate }}</p>
        <p class="font-para-20 p-justify">{{ "ACT_DESC_ABP_2" | translate }}</p>
      </div>
    </div>
    <br /><br />
    <table>
      <thead>
        <tr>
          <td></td>
          <th>OTAA</th>
          <th>ABP</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Device EUI</th>
          <td style="color: rgb(53, 175, 53)">✔</td>
          <td style="color: rgb(53, 175, 53)">✔</td>
        </tr>
        <tr>
          <th>Device Address</th>
          <td style="color: red">✘</td>
          <td style="color: rgb(53, 175, 53)">✔</td>
        </tr>
        <tr>
          <th>Network Session Key</th>
          <td style="color: red">✘</td>
          <td style="color: rgb(53, 175, 53)">✔</td>
        </tr>
        <tr>
          <th>Application Session Key</th>
          <td style="color: red">✘</td>
          <td style="color: rgb(53, 175, 53)">✔</td>
        </tr>
        <tr>
          <th>Application EUI</th>
          <td style="color: rgb(53, 175, 53)">✔</td>
          <td style="color: red">✘</td>
        </tr>
        <tr>
          <th>Application Key</th>
          <td style="color: rgb(53, 175, 53)">✔</td>
          <td style="color: red">✘</td>
        </tr>
      </tbody>

      <tbody></tbody>
    </table>
  </div>
</section>
<!-- End activation Area -->

<!-- Start event Area -->
<!-- <section class="section-gap" id="event">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="menu-content pb-60 col-lg-10">
        <div class="title text-center">
          <h1 class="mb-10">News & Events</h1>
          <p>Who are in extremely love with eco friendly system.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 single-blog">
        <img class="img-fluid" src="./assets/img/b1.jpg" alt="">
        <br>
        <br>
        <ul class="post-tags">
                <li>
                  <a href="#">Travel</a>
                </li>
                <li>
                  <a href="#">Life Style</a>
                </li>
              </ul>
        <a href="#">
          <h4>Portable latest Fashion for young women</h4>
        </a>
        <p class="post-date">
          31st January, 2018
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.
        </p>
        <button class="genric-btn primary-border radius" type="button">Read More</button>

      </div>
      <div class="col-lg-6 col-md-6 single-blog">
        <img class="img-fluid" src="./assets/img/b2.jpg" alt="">
        <br>
        <br>
        <ul class="post-tags">
                <li>
                  <a href="#">Travel</a>
                </li>
                <li>
                  <a href="#">Life Style</a>
                </li>
              </ul>
        <a href="#">
          <h4>Portable latest Fashion for young women</h4>
        </a>
        <p class="post-date">
          31st January, 2018
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.
        </p>
        <button class="genric-btn primary-border radius" type="button">Read More</button>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-6 col-md-6 single-blog">
        <img class="img-fluid" src="./assets/img/b1.jpg" alt="">
        <br>
        <br>
        <ul class="post-tags">
              <li>
                <a href="#">Travel</a>
              </li>
              <li>
                <a href="#">Life Style</a>
              </li>
            </ul>
        <a href="#">
          <h4>Portable latest Fashion for young women</h4>
        </a>
        <p class="post-date">
          31st January, 2018
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.
        </p>
        <button class="genric-btn primary-border radius" type="button">Read More</button>
      </div>
      <div class="col-lg-6 col-md-6 single-blog">
        <img class="img-fluid" src="./assets/img/b2.jpg" alt="">
        <br>
        <br>
        <ul class="post-tags">
              <li>
                <a href="#">Travel</a>
              </li>
              <li>
                <a href="#">Life Style</a>
              </li>
            </ul>
        <a href="#">
          <h4>Portable latest Fashion for young women</h4>
        </a>
        <p class="post-date">
          31st January, 2018
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.
        </p>
        <button class="genric-btn primary-border radius" type="button">Read More</button>
      </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <div class="row d-flex justify-content-center">
      <a class="genric-btn primary radius radius arrow e-large" type="button" target="_blank" [routerLink]="['/coverage']">Coverage
        <span class="lnr lnr-arrow-right"></span>
      </a>
    </div>
  </div>
</section> -->
<!-- End event Area -->

<!-- Start device Area -->
<!-- <section class="gallery-area section-gap" id="device">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="menu-content pb-60 col-lg-10">
        <div class="title text-center">
          <h1 class="mb-10">Devices</h1>
          <p>Who are in extremely love with eco friendly system.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <a href="./assets/img/g1.jpg" class="img-pop-home">
          <img class="img-fluid" src="./assets/img/g1.jpg" alt="">
        </a>
        <a href="./assets/img/g2.jpg" class="img-pop-home">
          <img class="img-fluid" src="./assets/img/g2.jpg" alt="">
        </a>
      </div>
      <div class="col-lg-8">
        <a href="./assets/img/g3.jpg" class="img-pop-home">
          <img class="img-fluid" src="./assets/img/g3.jpg" alt="">
        </a>
        <div class="row">
          <div class="col-lg-6">
            <a href="./assets/img/g4.jpg" class="img-pop-home">
              <img class="img-fluid" src="./assets/img/g4.jpg" alt="">
            </a>
          </div>
          <div class="col-lg-6">
            <a href="./assets/img/g5.jpg" class="img-pop-home">
              <img class="img-fluid" src="./assets/img/g5.jpg" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- End device Area -->

<!-- coverage Area -->
<!-- <section class="map-service-area" id="map-service-area-id">y
  <div class="container">
    <div class="row" style="height: 270px;"></div>
    <div id="title-coverage" class="row justify-content-center">
      <div class="col-10 col-offset-1" style="text-align: center;">
        <h1 style="color: #FFFFFF;">พื้นที่ครอบคลุมของสัญญาณ</h1>
        <br>
      </div>
    </div>
    <br>
    <div id="show-map" class="row justify-content-center">
      <button id="openmap" class="genric-btn primary-border radius" type="button" (click)="showMap()">ดูข้อมูล</button>

    </div>
    <div id="map-coverage">
    </div>
    <div class="row justify-content-center">
      <p id="remark-coverage">** ข้อมูลดังกล่าวเป็นการประมาณการพื้นที่ที่สามารถให้บริการได้ **</p>
    </div>
  </div>
</section> -->
<!-- End coverage Area -->

<!-- <section class="timeline-area" id="timeline-area-id">
  <div class="container">
    <div class="row" style="height: 8vw;"></div>

    <div class="col-md-12" style="text-align: center;">
      <h1 data-aos="fade-left" style="color: #FFFFFF;">LoRa Timeline</h1>
    </div>

    <div class="row" style="height: 2vw;"></div>
    <div class="col-md-12" style="text-align: center;">
      <h2 data-aos="fade-right" style="color: #FFD004;">{{ 'PROVINCE' | translate}}</h2>
    </div>
    <br>
    <div data-aos="fade-up">
      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'BKK' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'CBI' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'CBI' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'PRI' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'RYG' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'NMA' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'KKN' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'CMI' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'NAN' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'SKA' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'SNI' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'SRI' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'NBI' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'MKM' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'PTE' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'UDN' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'SPK' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'UBN' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'NPT' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'NPM' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'NYK' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'MDH' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'PKT' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'KBI' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'PNA' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'PTN' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'NRT' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'AYA' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'PLK' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'LPG' | translate}}</p>
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row">
        <div class="col-4 hidden-xs-down"></div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh">{{ 'LPN' | translate}}</p>
        </div>
        <div class="col-2 hidden-sm-up"></div>
        <div class="col-3">
          <p class="font-para-16-lh"></p>
        </div>
        <div class="col-2"></div>
      </div>
    </div>

    <div class="row" style="height: 3vw;"></div>
    <div data-aos="zoom-in" class="container">
      <h1 class="project-name">Coverage: 77 Provinces</h1>
      <div id="timeline">
        <div class="timeline-item">
          <div class="timeline-icon">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="21px" height="20px" viewBox="0 0 21 20"
              enable-background="new 0 0 21 20" xml:space="preserve">
              <path fill="#FFFFFF" d="M19.998,6.766l-5.759-0.544c-0.362-0.032-0.676-0.264-0.822-0.61l-2.064-4.999
    c-0.329-0.825-1.5-0.825-1.83,0L7.476,5.611c-0.132,0.346-0.462,0.578-0.824,0.61L0.894,6.766C0.035,6.848-0.312,7.921,0.333,8.499
    l4.338,3.811c0.279,0.246,0.395,0.609,0.314,0.975l-1.304,5.345c-0.199,0.842,0.708,1.534,1.468,1.089l4.801-2.822
    c0.313-0.181,0.695-0.181,1.006,0l4.803,2.822c0.759,0.445,1.666-0.23,1.468-1.089l-1.288-5.345
    c-0.081-0.365,0.035-0.729,0.313-0.975l4.34-3.811C21.219,7.921,20.855,6.848,19.998,6.766z" />
            </svg>

          </div>
          <div class="timeline-content">
            <h3>Gateway (1-31 August 2018)</h3>
            <br>
            <p class="info">
              LoRa Gateway Implementation in > 10 Cities
            </p>
          </div>
        </div>

        <div class="timeline-item">
          <div class="timeline-icon">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="21px" height="20px" viewBox="0 0 21 20"
              enable-background="new 0 0 21 20" xml:space="preserve">
              <g>
                <path fill="#FFFFFF" d="M17.92,3.065l-1.669-2.302c-0.336-0.464-0.87-0.75-1.479-0.755C14.732,0.008,7.653,0,7.653,0v5.6
      c0,0.096-0.047,0.185-0.127,0.237c-0.081,0.052-0.181,0.06-0.268,0.02l-1.413-0.64C5.773,5.183,5.69,5.183,5.617,5.215l-1.489,0.65
      c-0.087,0.038-0.19,0.029-0.271-0.023c-0.079-0.052-0.13-0.141-0.13-0.235V0H2.191C1.655,0,1.233,0.434,1.233,0.97
      c0,0,0.025,15.952,0.031,15.993c0.084,0.509,0.379,0.962,0.811,1.242l2.334,1.528C4.671,19.905,4.974,20,5.286,20h10.307
      c1.452,0,2.634-1.189,2.634-2.64V4.007C18.227,3.666,18.12,3.339,17.92,3.065z M16.42,17.36c0,0.464-0.361,0.833-0.827,0.833H5.341
      l-1.675-1.089h10.341c0.537,0,0.953-0.44,0.953-0.979V2.039l1.459,2.027V17.36L16.42,17.36z" />
              </g>
            </svg>

          </div>
          <div class="timeline-content right">
            <h3>Solution (1-31 September 2018)</h3>
            <br>
            <p class="info">
              Solution Showcase in Bangkok 2018 </p>
          </div>
        </div>

        <div class="timeline-item">
          <div class="timeline-icon">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="21px" height="20px" viewBox="0 0 21 20"
              enable-background="new 0 0 21 20" xml:space="preserve">
              <path fill="#FFFFFF" d="M19.998,6.766l-5.759-0.544c-0.362-0.032-0.676-0.264-0.822-0.61l-2.064-4.999
    c-0.329-0.825-1.5-0.825-1.83,0L7.476,5.611c-0.132,0.346-0.462,0.578-0.824,0.61L0.894,6.766C0.035,6.848-0.312,7.921,0.333,8.499
    l4.338,3.811c0.279,0.246,0.395,0.609,0.314,0.975l-1.304,5.345c-0.199,0.842,0.708,1.534,1.468,1.089l4.801-2.822
    c0.313-0.181,0.695-0.181,1.006,0l4.803,2.822c0.759,0.445,1.666-0.23,1.468-1.089l-1.288-5.345
    c-0.081-0.365,0.035-0.729,0.313-0.975l4.34-3.811C21.219,7.921,20.855,6.848,19.998,6.766z" />
            </svg>

          </div>
          <div class="timeline-content">
            <h3>Nationwide (1-31 January 2019)</h3>
            <br>
            <p class="info">
              Start Nationwide GW Implementation </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
      </div>
      <div class="col-md-6" style="margin-left: -50px; margin-top: -50px;">
    <div class="row" style="height: 5vw;"></div>
  </div>
</section> -->

<!-- Start map Area -->
<!-- <section class="map-coverage-area section-gap" id="map-coverage-area-id"></section> -->
<section class="timeline-area section-gap" id="map-coverage-area-id">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="menu-content pb-60 col-lg-10">
        <!-- <div class="col-md-12" data-aos="fade-left" style="text-align: center;">
              <h1>พื้นที่การให้บริการ</h1>
    
              <p>สามารภตรวจสอบพื้นที่ครอบคลุมของสัญญาณ LoRa ของ CAT Telecom ได้อย่างง่ายดาย
              </p>
            </div> -->
        <div class="text-center">
          <h1 data-aos="fade-left" style="color: #ffffff">
            {{ "TITLE_5" | translate }}
          </h1>
          <p data-aos="fade-right" style="color: #ffffff">
            {{ "SUB_TITLE_5" | translate }}
          </p>
        </div>
        <div class="row d-flex justify-content-center">
          <div
            *ngIf="coverageMode == 'noset'"
            class="menu-content pb-60 col-lg-10"
          >
            <div class="title text-center">
              <div class="manual-link"></div>
            </div>
            <div class="row justify-content-center">
              <button
                class="genric-btn primary circle btn-mode-coverage"
                (click)="showMapCover()"
              >
                <i class="fa fa-globe" aria-hidden="true"></i>&nbsp;Map Coverage
              </button>
            </div>
            <br /><br />
            <div class="row justify-content-center">
              <button
                class="genric-btn primary circle btn-mode-coverage"
                (click)="showCheckCover()"
              >
                <i class="fa fa-map-o" aria-hidden="true"></i>&nbsp;Check
                Coverage
              </button>
            </div>
          </div>

          <!-- Old Maparesix -->

          <div
            *ngIf="coverageMode == 'mapcover'"
            class="col-md-12 content-image-map-center"
          >
            <img
              id="img-map-coverage"
              src="./assets/img/LoRa2.png"
              alt="พื้นที่ครอบคลุมของสัญญาณ"
              title=""
              data-aos="zoom-in"
              (click)="openImgModel()"
            />
            <br />
            <button
              class="genric-btn circle btn-mode-coverage font-black"
              (click)="showCheckCover()"
            >
              <i class="fa fa-map-o font-black" aria-hidden="true"></i
              >&nbsp;Check Coverage
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12" id="maparea">
        <!-- <button class="genric-btn primary circle btn-mode-coverage" (click)="showMapCover()"><i class="fa fa-globe"
                aria-hidden="true"></i>&nbsp;Map Coverage</button>
            <br><br> -->

        <div class="mapbox-loading-cover" id="mapbox-loading-cover">
          <div class="mapbox-loading"></div>
        </div>
        <div class="mapbox" id="mapbox"></div>

        <div class="row">
          <!-- <div class="col-12 col-lg-8">
            <p>* {{ 'MAP_INFO' | translate}}</p>
            <p class="p-top-margin">(Update on week 13/2020)</p>
          </div> -->

          <div class="col-12 col-lg-4">
            <!-- <button class="genric-btn primary btn-mapbox-control pull-right" (click)='viewPlanCoverage()'><i
                    class="fa fa-arrow-circle-right" aria-hidden="true"></i>&nbsp;Check</button>
    
                <button class="genric-btn primary btn-mapbox-control pull-right" (click)='customLngLat()'><i
                  <div style="float: right;">
                    <div class="form-check form-check-inline">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="chkActualCoverage"
                          (change)="viewActualCoverage()"> {{ 'STATUS_MAP_1' | translate}}
                        <span class="form-check-sign"></span>
                      </label>
                    </div>
                    <div class="form-check form-check-plan form-check-inline">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="chkPlanCoverage"
                          (change)="viewPlanCoverage()"> {{ 'STATUS_MAP_2' | translate}}
                        <span class="form-check-sign"></span>
                      </label>
                    </div>
                  </div>
                    class="fa fa-arrow-circle-right" aria-hidden="true"></i>&nbsp;Check</button> -->
          </div>
        </div>
        <!-- <p>* ข้อมูลที่นำเสนอเกิดจากการประมาณการและอ้างอิงจากความแรงของสัญญาณสถานีฐาน LoRa</p> -->
        <!-- <div id='geocoder' class='geocoder'></div> -->
        <br />
        <h4 style="color: #ffffff">Search Location</h4>
        <div class="row">
          <div class="col-12 col-md-4">
            <br />
            <input
              type="text"
              class="form-control"
              id="getlatitude"
              [(ngModel)]="getLatitude"
              placeholder="Latitude"
            />
          </div>
          <div class="col-12 col-md-4">
            <br />
            <input
              type="text"
              class="form-control"
              id="getlongitude"
              [(ngModel)]="getLongitude"
              placeholder="Longitude"
            />
          </div>

          <div class="col-6 col-md-2">
            <br />
            <button
              class="genric-btn primary btn-mapbox-control font-black"
              (click)="customLngLat()"
            >
              <i class="fa fa-arrow-circle-right" aria-hidden="true"></i
              >&nbsp;Check
            </button>
          </div>

          <div class="col-6 col-md-2">
            <br />
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="showImportFile"
                  (change)="showViewImportFile(showImportFile ? 'A' : 'B')"
                />
                {{ "MAP_IMPORT_FILE" | translate }}
                <span class="form-check-sign"></span>
              </label>
            </div>
          </div>
        </div>
        <div *ngIf="showImportFile">
          <div class="row">
            <br />
            <div class="col-12 col-md-12">
              <br />
              <br />
              <p>{{ "TITLE_IMPORT_FILE" | translate }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-9 col-md-4">
              <!-- <input type="file" id="myFile"> -->
              <div
                id="file-upload-wrapper"
                class="file-upload-wrapper"
                data-text="Select your file"
              >
                <input
                  id="file-upload-field"
                  #fileInput
                  name="file-upload-field"
                  type="file"
                  accept=".csv"
                  class="file-upload-field"
                  (change)="changeListener($event.target.files)"
                  value=""
                />
              </div>
            </div>
            <div class="col-3 col-md-1">
              <button
                class="genric-btn primary btn-mapbox-control-clear"
                (click)="removeAllMarker()"
              >
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
            <div class="col-12 col-md-4">
              <a
                class="download"
                (click)="downloadCSV({ filename: 'exampleFile.csv' }, 'new')"
                ><i class="ti-download"></i> Download Example file .csv</a
              >
            </div>
          </div>
        </div>

        <!-- <button class="genric-btn primary btn-mapbox-control" data-toggle="collapse" href="#collapseExample"><i
                class="fa fa-search" aria-hidden="true"></i>&nbsp;Search</button>
            <button id="btn-marker" class="genric-btn primary btn-mapbox-control" (click)='userMarker()'><i
                class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;Marker</button>
            <button class="genric-btn primary btn-mapbox-control" (click)='removeMarker()'><i class="fa fa-trash-o"
                aria-hidden="true"></i>&nbsp;Remove All</button>
    
            <div class="collapse" id="collapseExample">
              <div class="row">
                <div class="col-12 col-md-4">
                  <br>
                  <input type="text" class="form-control" id="getlatitude" [(ngModel)]="getLatitude"
                    placeholder="Latitude">
                </div>
                <div class="col-12 col-md-4">
                  <br>
                  <input type="text" class="form-control" id="getlongitude" [(ngModel)]="getLongitude"
                    placeholder="Longitude">
                </div>
                <div class="col-12 col-md-4">
                  <br>
                  <button class="genric-btn primary btn-mapbox-control" (click)='customLngLat()'><i
                      class="fa fa-arrow-circle-right" aria-hidden="true"></i>&nbsp;Check</button>
                </div>
              </div>
              
            </div> -->
      </div>
    </div>

    <div id="myModal" class="modal-img" (click)="closeImgModel()">
      <span class="close" style="margin-top: 50px" (click)="closeImgModel()"
        >&times;</span
      >
      <img class="modal-img-content" id="img01" />
      <div id="caption"></div>
    </div>

    <div class="row" style="height: 10vh"></div>
  </div>
</section>
<!-- End map Area -->

<!-- Start package Area -->
<section class="blog-area section-gap" id="products">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="menu-content pb-60 col-lg-10">
        <div class="title text-center">
          <h1 class="mb-10">{{ "TITLE_6" | translate }}</h1>
          <!-- <p>{{ 'SUB_TITLE_6' | translate}}</p> -->
        </div>
      </div>
    </div>
    <div class="row justify-content-center inline-content">
      <div class="row">
        <div class="col-md-4">
          <img class="image-connectivity" src="assets/img/lora-package.jpg" />
        </div>
        <div class="col-md-8">
          <br />
          <h3 class="mb-10">{{ "PRODUCT_COMMENT_1" | translate }}</h3>
          <br />
          <div class="flex-content">
            <h4 class="mb-10">{{ "PRICE_1" | translate }}</h4>
            <h5 class="text-vat">{{ "PRODUCT_VAT" | translate }}</h5>
          </div>
          <h6 class="mb-10 font-black">{{ "PRODUCT_TYPE_1" | translate }} 1</h6>
          <button
            class="genric-btn primary btn-mapbox-control font-black"
            (click)="openCompareModel()"
          >
            {{ "COMPARE_PACKAGES" | translate }}
          </button>
          <br />
          <br />
          <br />
          <h5 class="mb-10">{{ "CONNECTIVITY_CONTACT" | translate }}</h5>
        </div>
      </div>

      <div id="modal-compare" class="modal-packages-container">
        <div class="packages-container">
          <h2>Packages</h2>
          <div class="table-packages">
            <table class="table-package">
              <tr>
                <th>Title</th>
                <th>ลูกค้าทั่วไป (Basic)</th>
                <th>ลูกค้าโครงการ <b>(Premium)</b></th>
              </tr>
              <tr>
                <td>Message transfer</td>
                <td>80 Uplink/Days<br />24 Downlink/Days</td>
                <td><b>Unlimited*</b><br /><b>Unlimited*</b></td>
              </tr>
              <tr>
                <td>Confirmed Message Mode</td>
                <td>No</td>
                <td><b>Yes</b></td>
              </tr>
              <tr>
                <td>Data Rate</td>
                <td>SF10-SF12 (980-250 bps)</td>
                <td><b>SF7-SF12 (50,000-250 bps)</b></td>
              </tr>
              <tr>
                <td>Application Servers Routing</td>
                <td>2</td>
                <td><b>10</b></td>
              </tr>
              <tr>
                <td>Data logger (Buffer)</td>
                <td>100 Row/Device</td>
                <td><b>Unlimited</b></td>
              </tr>
              <tr>
                <td>Geolocation</td>
                <td>No</td>
                <td><b>Yes</b></td>
              </tr>
              <tr>
                <td>Base Station Metadata info</td>
                <td>No</td>
                <td><b>Yes</b></td>
              </tr>
              <tr>
                <td>Packet error rate Info</td>
                <td>No</td>
                <td><b>Yes</b></td>
              </tr>
              <tr>
                <td>Hotline Contact Center 24x7 Support & Monitoring</td>
                <td>No</td>
                <td><b>Yes</b></td>
              </tr>
            </table>
          </div>
          <p class="denote-packages">{{ "DENOTE_PACKAGES" | translate }}</p>
          <div class="btn-packages-close">
            <button
              class="genric-btn primary circle btn-buynow"
              (click)="closeCompareModel()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center inline-content div-hidden">
      <!-- <div class="col-1 col-md-1">
        <button class="btn direction-btn-left" (click)="moveLeft()"><i id="arrow-left" class="fa fa-caret-left fa-5x"
            aria-hidden="true"></i></button>
      </div> -->

      <!-- <div class="col-12 col-md-12"> -->
      <!-- <button class="btn direction-btn-left" (click)="moveLeft()"><i id="arrow-left" class="fa fa-caret-left fa-5x"></i></button> -->

      <button class="direction-btn-left" (click)="moveLeft()">
        <i id="arrow-left" class="fa fa-caret-left"></i>
      </button>
      <button class="direction-btn-right" (click)="moveRight()">
        <i id="arrow-left" class="fa fa-caret-right"></i>
      </button>

      <drag-scroll #nav>
        <div drag-scroll-item *ngFor="let item of itemss; let i = index">
          <div class="card clean-card text-center slick-card">
            <div class="card-body info">
              <h2 class="card-titile">{{ item.title }}</h2>
              <hr />
              <div class="features">
                <img class="card-img w-100 d-block" src="{{ item.image }}" />
                <br />
                <div style="min-height: 150px">
                  <p class="font-para-16 wrapWord">
                    {{ item.comment | translate }}
                  </p>
                </div>

                <h4>
                  <span class="feature">{{ item.type | translate }}&nbsp;</span>
                  <span>{{ item.amount }}</span>
                </h4>
              </div>
              <hr />
              <h1 *ngIf="item.price === 'PRICE_1'" class="price-packages">
                {{ item.price | translate }}
              </h1>
              <h4 *ngIf="item.price != 'PRICE_1'" class="price">
                {{ item.price | translate }}
              </h4>
              <span class="priceVat">{{ item.vat | translate }}</span>
              <br />
              <a
                href="javascript:void(0)"
                *ngIf="item.price === 'PRICE_1'"
                (click)="openCompareModel()"
                >{{ "COMPARE_PACKAGES" | translate }}</a
              >
              <br />
              <br />
              <button
                *ngIf="item.buy"
                class="genric-btn primary circle btn-buynow"
                (click)="buyProduct('test')"
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </drag-scroll>

      <!-- The Modal -->
      <div id="modal-buy-product" class="modal">
        <!-- Modal content -->
        <div class="buy-product-content">
          <div class="row">
            <div class="col-4">
              <button
                *ngIf="showFormInvoice"
                class="genric-btn primary circle btn-close-modal"
                (click)="backFormInvoice()"
              >
                <i class="fa fa-reply" aria-hidden="true"></i>
              </button>
            </div>
            <div class="col-4">
              <div class="title text-center">
                <h4 class="mb-10">LoRa Connectivity</h4>
                <!-- <p>สินค้าและบริการของ LoRa</p> -->
              </div>
            </div>
            <div class="col-4">
              <button
                class="genric-btn primary circle btn-close-modal pull-right"
                (click)="closeModal('modal-buy-product')"
              >
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </div>

          <div *ngIf="!showFormInvoice">
            <div class="row" style="height: 8vh"></div>

            <div class="row">
              <div class="col-md-6">
                <div
                  class="card card-buy-connectivity"
                  (click)="buyConnectivity()"
                >
                  <br />
                  <div class="title text-center">
                    <i class="fa fa-times fa-2x font-red" aria-hidden="true"></i
                    >&nbsp;&nbsp;
                    <i
                      class="fa fa-file-text-o fa-3x font-black"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <br />
                  <!-- <i class="fa fa-file-text-o font-black" aria-hidden="true"></i> -->
                  <p class="font-para-16 font-black wrapWordC">
                    <!-- สำหรับลูกค้าบุคคลที่ไม่ต้องการใบเสร็จรับเงิน/ใบกำกับภาษี สามารถซื้อ LoRa Network Connectivity ได้ที่
                    CAT Shopping
                    โดยระบบจะจัดส่งคู่มือการใช้งานพร้อม Voucher ให้ทาง e-mail ที่ท่านระบุ -->
                    {{ "BUY_CONNECTIVITY_CONDITION1" | translate }}
                  </p>
                  <br />
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="card card-buy-connectivity"
                  (click)="emailContact()"
                >
                  <br />
                  <div class="title text-center">
                    <i
                      class="fa fa-check fa-2x font-green"
                      aria-hidden="true"
                    ></i
                    >&nbsp;&nbsp;
                    <i
                      class="fa fa-file-text-o fa-3x font-black"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <br />
                  <!-- <i class="fa fa-file-text-o font-black" aria-hidden="true"></i> -->
                  <p class="font-para-16 font-black wrapWordC">
                    <!-- สำหรับลูกค้าองค์กร/นิติบุคคล/ลูกค้าที่ต้องการใบเสร็จรับเงิน/ใบกำกับภาษี สามารถขอใช้บริการ LoRa
                    Network Connectivity ได้ที่<br>e-mail : iot@cattelecom.com -->
                    {{ "BUY_CONNECTIVITY_CONDITION2" | translate }}
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="showFormInvoice" class="form-invoice">
            <!-- <br />
            <div class="row">
              <div class="col-md-12">
                <p class="font-para-16 font-black wrapWord">
                  {{ "INVOICE_FORM" | translate }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p class="font-para-16 font-black wrapWord">
                  <b>{{ "INVOICE_PAYMENT" | translate }}</b>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p class="font-para-16 font-black wrapWord">
                  {{ "INVOICE_PAYMENT_DETAIL_1" | translate }} <br />{{
                    "INVOICE_PAYMENT_DETAIL_2" | translate
                  }}
                  <br />
                  <br />{{ "INVOICE_PAYMENT_DETAIL_3" | translate }} <br />{{
                    "INVOICE_PAYMENT_DETAIL_4" | translate
                  }}
                  <br />{{ "INVOICE_PAYMENT_DETAIL_5" | translate }}
                  <br />
                </p>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <p class="font-para-16 font-black wrapWord">
                  <b>{{ "INVOICE_BUYER" | translate }}</b>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-para-16 font-black wrapWord">
                  {{ "INVOICE_BUYER_DETAIL_1" | translate }}
                </p>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="getlatitude"
                  [(ngModel)]="custumerName"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-para-16 font-black wrapWord">
                  {{ "INVOICE_BUYER_DETAIL_2" | translate }}
                </p>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="getlatitude"
                  [(ngModel)]="custimerAddress"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-para-16 font-black wrapWord">
                  {{ "INVOICE_BUYER_DETAIL_3" | translate }}
                </p>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="getlatitude"
                  [(ngModel)]="customerId"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-para-16 font-black wrapWord">
                  {{ "INVOICE_BUYER_DETAIL_4" | translate }}
                </p>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="getlatitude"
                  [(ngModel)]="customerEmail"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-para-16 font-black wrapWord">
                  {{ "INVOICE_BUYER_DETAIL_5" | translate }}
                </p>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="getlatitude"
                  [(ngModel)]="customerPhone"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-para-16 font-black wrapWord">
                  {{ "INVOICE_BUYER_DETAIL_6" | translate }}
                </p>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="getlatitude"
                  [(ngModel)]="amountConnectivity"
                />
              </div>
            </div>
            <br /><br />
            <div class="row">
              <div class="col-md-12">
                <p class="font-para-16 font-black wrapWord">
                  <b>{{ "INVOICE_ADDR" | translate }}</b>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-para-16 font-black wrapWord">
                  {{ "INVOICE_ADDR_DETAIL_1" | translate }}
                </p>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="getlatitude"
                  [(ngModel)]="shippingName"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-para-16 font-black wrapWord">
                  {{ "INVOICE_ADDR_DETAIL_2" | translate }}
                </p>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="getlatitude"
                  [(ngModel)]="shippingCompanyName"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-para-16 font-black wrapWord">
                  {{ "INVOICE_ADDR_DETAIL_3" | translate }}
                </p>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="getlatitude"
                  [(ngModel)]="shippingAddress"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-para-16 font-black wrapWord">
                  {{ "INVOICE_ADDR_DETAIL_4" | translate }}
                </p>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="getlatitude"
                  [(ngModel)]="shippingPhone"
                />
              </div>
            </div>

            <br />
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <button
                  class="genric-btn primary circle btn-buynow"
                  (click)="sendEmail()"
                >
                  Send Email
                </button>
              </div>
              <div class="col-md-4"></div>
            </div>
            <br /> -->
          </div>
        </div>
      </div>

      <!-- [style.margin-left.px]="slickWidth-19" -->

      <!-- </div> -->
      <!-- <div class="col-1 col-md-1">
        <button class="btn direction-btn-right pull-right" (click)="moveRight()"><i id="arrow-left" class="fa fa-caret-right fa-5x"
            aria-hidden="true"></i></button>
      </div> -->
      <!-- <div ng2-carouselamos [width]="slickWidth" [items]="itemss" [$item]="itemTemplate" [$prev]="prevTemplate"
        [$next]="nextTemplate" (onSelectedItem)="selectedItem = $event.item; selectedIndex = $event.index"></div>

      <ng-template #prevTemplate>
        <button *ngIf="indexProduct != 0" type="button" class="btn direction-btn-left" (click)="prevPro()">
          <i id="arrow-left" class="fa fa-caret-left fa-5x" aria-hidden="true"></i>
        </button>
      </ng-template>

      <ng-template #nextTemplate>

        <button *ngIf="indexProduct != (itemss.length - 1)" type="button" class="btn direction-btn-right"
          (click)="nextPro()">
          <i id="arrow-left" class="fa fa-caret-right fa-5x" aria-hidden="true"></i>
        </button>
      </ng-template> -->

      <!-- <ng-template let-item let-i="index" #itemTemplate>
        <div class="card clean-card text-center slick-card">
          <div class="card-body info">
            <h2 class="card-titile">{{ item.title }}</h2>
            <hr>
            <div class="features">
              <img class="card-img w-100 d-block" src="{{ item.image }}">
              <br>
              <div style="min-height: 150px;">
                <p class="font-para-16 card-text">{{ item.comment }}</p>
              </div>

              <h4>
                <span class="feature">{{ item.type }}&nbsp;</span>
                <span>{{ item.amount }}</span>
              </h4>
            </div>
            <hr>
            <h4 class="price">{{ item.price }}</h4>
            <span class="priceVat">{{ item.vat }}</span>
          </div>
        </div>
      </ng-template> -->
    </div>
  </div>
</section>
<!-- End package Area -->

<!-- Start platform Area -->
<section class="section-gap manual-area" id="platform">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="menu-content pb-60 col-lg-10">
        <div class="title text-center">
          <h1 class="mb-10">{{ "TITLE_7" | translate }}</h1>
          <p>{{ "SUB_TITLE_7" | translate }}</p>
          <div class="manual-link">
            <a
              target="_blank"
              class="font-black"
              href="https://drive.google.com/file/d/1pv8wswpUIHbdy_NpPOI3v1vGRsd6nzFj/view"
              ><i class="ti-import"></i> {{ "MANUAL_TITLE" | translate }}</a
            >
            <!-- <p style="font-size:14px;">( {{ 'MANUAL_UPDATE' | translate}})
            </p> -->
          </div>
        </div>

        <div class="row justify-content-center">
          <!-- <a class="genric-btn primary-border radius" type="button" target="_blank" href="https://lora.ntdigitalsolutions.com/portal">Join
            Us</a> -->
          <a
            target="_blank"
            href="https://lora.ntdigitalsolutions.com/portal"
            class="genric-btn primary circle arrow font-black"
            >Go to Platform<span class="lnr lnr-arrow-right"></span
          ></a>
        </div>
      </div>
    </div>
    <!-- <img class="w-100 d-block" src="./assets/img/bg.png"> -->
    <!-- <div class="carousel slide" data-ride="carousel" id="carousel-1">
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
          <img class="w-100 d-block" src="./assets/img/b1.jpg" alt="Slide Image">
        </div>
        <div class="carousel-item">
          <img class="w-100 d-block" src="./assets/img/b2.jpg" alt="Slide Image">
        </div>
        <div class="carousel-item">
          <img class="w-100 d-block" src="./assets/img/b1.jpg" alt="Slide Image">
        </div>
        <div class="carousel-item">
          <img class="w-100 d-block" src="./assets/img/b2.jpg" alt="Slide Image">
        </div>
      </div>
      <div>
        <a class="carousel-control-prev" href="#carousel-1" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carousel-1" role="button" data-slide="next">
          <span class="carousel-control-next-icon"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <ol class="carousel-indicators">
        <li data-target="#carousel-1" data-slide-to="0" class="active"></li>
        <li data-target="#carousel-1" data-slide-to="1"></li>
        <li data-target="#carousel-1" data-slide-to="2"></li>
        <li data-target="#carousel-1" data-slide-to="3"></li>
      </ol>
    </div> -->
  </div>
</section>
<!-- End platform Area -->

<!-- Start about us Area -->
<section class="section-gap" id="aboutus">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="menu-content pb-60 col-lg-10">
        <div class="title text-center">
          <h1 class="mb-10">About Us</h1>
          <!-- <p>Who are in extremely love with eco friendly system.</p> -->
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-6 col-lg-4">
        <div class="card clean-card text-center">
          <img
            class="card-img-top w-100 d-block"
            id="img-about-cat"
            src="assets/img/img-about-cat.png"
          />
          <div class="card-body" style="height: 42vh; overflow: scroll">
            <h4 class="card-title">{{ "ABOUTUS_TITLE_1" | translate }}</h4>
            <p class="card-text">{{ "ABOUTUS_DESC_1_1" | translate }}</p>
            <p>{{ "ABOUTUS_DESC_1_2" | translate }}</p>
            <p>{{ "ABOUTUS_DESC_1_3" | translate }}</p>
            <p>{{ "ABOUTUS_DESC_1_4" | translate }}</p>
            <!-- <button class="genric-btn primary-border radius" type="button">Read More</button> -->
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4">
        <div class="card clean-card text-center">
          <img
            class="card-img-top w-100 d-block"
            id="img-about-lora"
            src="assets/img/img-about-lora.png"
          />
          <div class="card-body" style="height: 42vh; overflow: scroll">
            <h4 class="card-title">{{ "ABOUTUS_TITLE_2" | translate }}</h4>
            <p class="card-text">{{ "ABOUTUS_DESC_2_1" | translate }}</p>
            <p>{{ "ABOUTUS_DESC_2_2" | translate }}</p>
            <p>{{ "ABOUTUS_DESC_2_3" | translate }}</p>
            <!-- <button class="genric-btn primary-border radius" type="button">Read More</button> -->
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4">
        <div class="card clean-card text-center">
          <img
            class="card-img-top w-100 d-block"
            id="img-about-platform"
            src="assets/img/img-about-platform2.png"
          />
          <div class="card-body" style="height: 42vh; overflow: scroll">
            <h4 class="card-title">{{ "ABOUTUS_TITLE_3" | translate }}</h4>
            <p class="card-text">{{ "ABOUTUS_DESC_3_1" | translate }}</p>
            <!-- <button class="genric-btn primary-border radius" type="button">Read More</button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <br>
    <div class="row justify-content-center">
      <div class="col-sm-6 col-lg-4">
        <div class="card clean-card text-center">
          <img class="card-img-top w-100 d-block" src="assets/img/g5.jpg">
          <div class="card-body info">
            <h4 class="card-title">Lorem Ipsum</h4>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            <button class="genric-btn primary-border radius" type="button">Read More</button>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4">
        <div class="card clean-card text-center">
          <img class="card-img-top w-100 d-block" src="assets/img/g5.jpg">
          <div class="card-body info">
            <h4 class="card-title">Lorem Ipsum</h4>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            <button class="genric-btn primary-border radius" type="button">Read More</button>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4">
        <div class="card clean-card text-center">
          <img class="card-img-top w-100 d-block" src="assets/img/g5.jpg">
          <div class="card-body info">
            <h4 class="card-title">Lorem Ipsum</h4>
            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            <button class="genric-btn primary-border radius" type="button">Read More</button>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <div class="row d-flex justify-content-center">
      <a class="genric-btn primary radius radius arrow e-large" type="button" target="_blank" [routerLink]="['/partner']">Partner
        <span class="lnr lnr-arrow-right"></span>
      </a>
    </div> -->
  </div>
</section>
<!-- End about us Area -->

<!-- Start contact Area -->
<section class="contactus-area" id="contactus-area-id">
  <!-- <div id='headerContactUs'> 
    <i class="ti-announcement"></i> เนื่องจากสถานการณ์การแพร่ระบาดของเชื้อไวรัส Covid-19 บริษัท
    กสท โทรคมนาคม จำกัด (มหาชน)
    ให้พนักงานปฏิบัติงานภายในที่พัก (Work From Home) จึงแนะนำให้ลูกค้าติดต่อและสอบถามข้อมูลผ่านช่องทาง E-mail :
    iot@cattelecom.com </div> -->
  <div class="container">
    <div class="row" style="height: 5vw"></div>
    <div class="row d-flex justify-content-center">
      <div class="menu-content pb-60 col-lg-10">
        <div class="title text-center">
          <h1 class="mb-10" style="color: #000000">Contact Us</h1>
          <!-- <p>Who are in extremely love with eco friendly system.</p> -->
        </div>
      </div>
    </div>
    <!-- <div class="row justify-content-center">
      <div class="col-sm-6 col-lg-4">
        <form>
          <div class="form-group">
            <label>Name</label>
            <input class="form-control" type="text">
          </div>
          <div class="form-group">
            <label>Subject</label>
            <input class="form-control" type="text">
          </div>
          <div class="form-group">
            <label>Email</label>
            <input class="form-control" type="text">
          </div>
          <div class="form-group">
            <label>Message</label>
            <textarea class="form-control"></textarea>
          </div>
          <div class="form-group">
            <button class="genric-btn primary radius btn-block" type="button">Send</button>
          </div>
        </form>
      </div>
    </div> -->
    <br />
    <div class="row justify-content-center">
      <div class="col-2 col-md-3">
        <i class="ti-email pull-right font-black"></i>
      </div>
      <div class="col-10 col-md-6">
        <a
          style="color: #000000; font-size: 16px"
          href="mailto:iot@cattelecom.com"
          >iot@nc.ntplc.co.th</a
        >
      </div>
    </div>
    <!-- <br>
    <div class="row justify-content-center">
      <div class="col-2 col-md-3">
        <i class="ti-facebook pull-right"></i>
      </div>
      <div class="col-10 col-md-6 word-wrap">
        <a style="color: #FFFFFF; font-size: 16px;"
          href="https://www.facebook.com/loraiotbycat/">https://www.facebook.com/loraiotbycat</a>
      </div>
    </div> -->
    <!-- <br>
    <div class="row justify-content-center">
      <div class="col-2 col-md-3">
        <i class="ti-mobile pull-right"></i>
      </div>
      <div class="col-10 col-md-6">
        <a style="color: #FFFFFF; font-size: 16px;" href="tel:+6621043551">0-2104-3551</a> (ในวันและเวลาทำการ)
      </div>
    </div> -->
    <br />
    <div class="row justify-content-center">
      <div class="col-2 col-md-3">
        <img
          id="img-icon-line"
          class="pull-right"
          src="./assets/img/line-black-icon.png"
        />
      </div>
      <div class="col-10 col-md-6">
        <a style="color: #000000; font-size: 16px" href="line://ti/p/@iqc1156c"
          >Line Chatbot</a
        >
      </div>
    </div>
    <div
      class="row justify-content-center font-black"
      style="text-align: center; margin-top: 30px"
    >
      <div class="col-12">
        <img
          id="qr-line-lora"
          class="font-black"
          src="./assets/img/qr-line-lora.png"
        />
      </div>
    </div>
    <div class="row" style="height: 5vw"></div>
  </div>
</section>

<!-- <div id="pop-message" class="notice-popup">
  <div class="pop-body">
    <p class="text-popup-title">เรียนผู้ใช้บริการ</p>
    <p class="text-popup">บริษัท โทรคมนาคม
      แห่งชาติ จำกัด ขอแจ้งวันสิ้นสุดการให้บริการ LoRa ในวันที่ 23 ธันวาคม 2567 เป็นต้นไป ขอบคุณที่ไว้วางใจใช้
      บริการ และขออภัยในความไม่สะดวกมา ณ ที่นี้</p>
    <button class="btn-pop genric-btn primary radius btn-block" type="button" (click)="closePopup()"> ปิดข้อความ</button>
  </div>
</div> -->

<!-- <div class="notice-banner">
  <p class="text-banner">บริษัท โทรคมนาคม
    แห่งชาติ จำกัด ขอแจ้งวันสิ้นสุดการให้บริการ LoRa ในวันที่ 23 ธันวาคม 2567 เป็นต้นไป ขอบคุณที่ไว้วางใจใช้
    บริการ และขออภัยในความไม่สะดวกมา ณ ที่นี้</p>
</div> -->
<!-- End contact Area -->

<!-- Start review Area -->
<!-- <section class="review-area section-gap" id="faq">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="menu-content pb-60 col-lg-10">
        <div class="title text-center">
          <h1 class="mb-10">What kind of Coffee we serve for you</h1>
          <p>Who are in extremely love with eco friendly system.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 single-review">
        <img src="./assets/img/r1.png" alt="">
        <div class="title d-flex flex-row">
          <h4>lorem ipusm</h4>
          <div class="star">
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </div>
        </div>
        <p>
          Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker. Here you can
          find the best computer accessory for your laptop, monitor, printer, scanner, speaker.
        </p>
      </div>
      <div class="col-lg-6 col-md-6 single-review">
        <img src="./assets/img/r2.png" alt="">
        <div class="title d-flex flex-row">
          <h4>lorem ipusm</h4>
          <div class="star">
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </div>
        </div>
        <p>
          Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker. Here you can
          find the best computer accessory for your laptop, monitor, printer, scanner, speaker.
        </p>
      </div>
    </div>
    <div class="row counter-row">
      <div class="col-lg-3 col-md-6 single-counter">
        <h1 class="counter">2536</h1>
        <p>Happy Client</p>
      </div>
      <div class="col-lg-3 col-md-6 single-counter">
        <h1 class="counter">7562</h1>
        <p>Total Projects</p>
      </div>
      <div class="col-lg-3 col-md-6 single-counter">
        <h1 class="counter">2013</h1>
        <p>Cups Coffee</p>
      </div>
      <div class="col-lg-3 col-md-6 single-counter">
        <h1 class="counter">10536</h1>
        <p>Total Submitted</p>
      </div>
    </div>
  </div>
</section> -->

<!-- End review Area -->
