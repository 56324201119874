import { environment } from '../../environments/environment';
import 'rxjs/add/operator/map';
import { TranslationTable } from '../i18n/translation-table';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var TranslationService = /** @class */ (function () {
    function TranslationService() {
        this.subject = new Subject();
        this.translationTable = TranslationTable;
        // console.log("environment.default.language:",environment.default.language);
        this.setLanguage(environment.default.language);
    }
    TranslationService.prototype.setLanguage = function (lang) {
        localStorage.setItem('lang', lang);
        // console.warn("lang:",lang);
        if (this.currentLanguage !== lang) {
            this.currentLanguage = lang;
            this.subject.next();
        }
    };
    TranslationService.prototype.getLanguage = function () {
        return this.currentLanguage;
    };
    TranslationService.prototype.setTranslationTable = function (table) {
        this.translationTable = table;
    };
    TranslationService.prototype.translate = function (key) {
        if (!this.translationTable.hasOwnProperty(this.currentLanguage)
            || !this.translationTable[this.currentLanguage].hasOwnProperty(key)) {
            return key;
        }
        return this.translationTable[this.currentLanguage][key];
    };
    TranslationService.prototype.translationChanged = function () {
        return this.subject.asObservable();
    };
    TranslationService.ngInjectableDef = i0.defineInjectable({ factory: function TranslationService_Factory() { return new TranslationService(); }, token: TranslationService, providedIn: "root" });
    return TranslationService;
}());
export { TranslationService };
