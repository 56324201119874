<header id="header">
  <div class="container">
    <div class="row align-items-center justify-content-between d-flex">
      <div id="logo">
        <a href="index.html">
          <img id="img-logo" src="./assets/img/logo.png" alt="" title="" />
        </a>
      </div>
      <nav id="nav-menu-container">
        <ul class="nav-menu">
          <li class="menu-active">
            <a [routerLink]="['/home']">Home</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'info' }">Info</a>
          </li> -->
          <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'feature' }">Features</a>
          </li>
          <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'map-coverage' }">Coverage Area</a>
          </li>
          <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'products' }">Products</a>
          </li>
          <li>
            <a [routerLink]="['/events']">News & Events</a>
          </li>
          <li>
            <a [routerLink]="['/elearning']">E-Learning</a>
          </li>
          <li>
            <a [routerLink]="['/usecase']">Use Cases</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'aboutus' }">About us</a>
          </li> -->
          <li>
            <br><!-- <a [routerLink]="['/home']" [queryParams]="{ section: 'aboutus' }">About us</a> -->
          </li>
          <li class="hidden-md-up">
            <a href="javascript:void(0)">Support</a>
          </li>
          <li class="hidden-md-up">
            <a [routerLink]="['/faq']">&nbsp;&nbsp;> FAQ</a>
          </li>
          <li class="hidden-md-up">
            <a [routerLink]="['/download']">&nbsp;&nbsp;> LoRa Document</a>
          </li>
          <li class="hidden-sm-down">
            <div class="dropdown">
              <a href="javascript:void(0)">Support</a>
              <div class="dropdown-content">
                <a [routerLink]="['/faq']">FAQ</a>
                <a [routerLink]="['/download']"> LoRa Document</a>
              </div>
            </div>
          </li>
        </ul>
      </nav>
      <!-- #nav-menu-container -->
      <!-- <div id="language">
        <a href="javascript:void(0)" (click)="changeLanguage('en')"> <img [ngClass]="{'img-grey':this.language == 'en'}"
            id="img-lang" src="./assets/img/en.png" alt="" title="" /></a>
        <a href="javascript:void(0)" (click)="changeLanguage('th')"><img [ngClass]="{'img-grey':this.language == 'th'}"
            id="img-lang" src="./assets/img/th.png" alt="" title="" /></a>
      </div> -->
    </div>
  </div>
</header>
<!-- #header -->

<!-- About Generic Start -->
<div class="main-wrapper">
  <br>
  <br>
  <br>
  <section id="elearning-area-id" class="section-gap">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="menu-content pb-60 col-lg-10">
          <div class="title text-center">
            <h1 class="mb-10">E - Learning</h1>
            <p>สื่อการเรียนการสอนเกี่ยวกับการใช้งาน Platform และอุปกรณ์ต่างๆ ที่รองรับโครงข่าย LoRa
              เพื่อให้ผู้ใช้สามารถนำไปพัฒนาต่อยอดต่อไป</p>
          </div>
        </div>
      </div>
      <div class="visible-xs-block visible-sm-block visible-md-block" ng-controller="PlayerController">
        <!-- <div [innerHtml]="yt_iframe_html"></div>
    
          <div class="controls">
            <img class="vid-list-containerM" *ngFor="let id of youtubeLst" src={{id.image}}
              (click)="changeSlide(id.code)" />
            <img src="http://placehold.it/100x60/F36/fff" alt="two" (click)="changeSlide('LrUvu1mlWco')" />
            <img src="http://placehold.it/100x60/093/fff" alt="three" (click)="changeSlide('CevxZvSJLk8')" />
          </div> -->
  
        <!-- <div class="row justify-content-center">
          <div ng2-carouselamos [width]="slickWidth" [items]="youtubeLst" [$item]="itemTemplate1" [$prev]="prevTemplate1"
            [$next]="nextTemplate1"></div>
          <ng-template #prevTemplate1>
            <button type="button" class="btn direction-btn-left">
              <i id="arrow-left" class="fa fa-caret-left fa-5x" aria-hidden="true"></i>
            </button>
          </ng-template>
  
          <ng-template #nextTemplate1>
            <button type="button" class="btn direction-btn-right">
              <i id="arrow-left" class="fa fa-caret-right fa-5x" aria-hidden="true"></i>
            </button>
          </ng-template>
  
          <ng-template let-item let-i="index" #itemTemplate1>
            <div class="card clean-card text-center slick-cardY">
              <div class="card-body info">
                <h2 class="card-titile">{{ item.code }}</h2>
                  <hr>
                <br>
                <br>
                <a class="play-btn" href="https://www.youtube.com/watch?v={{item.code}}">
                  <img class="card-img w-100 d-block" src="{{ item.image }}">
                  <div class="centered"><img src="./assets/img/play-icon2.png" alt="">
                  </div>
                </a>
                <br>
                <div style="min-height: 150px;">
                  <p class="font-para-16 card-text">{{ item.desc }}</p>
                </div>
                <br><br>
                <button class="genric-btn primary" (click)='userMarker()'>Marker</button>
  
              </div>
            </div>
          </ng-template>
        </div> -->
        <div class="row justify-content-center inline-content">
  
          <button class="direction-btn-left" (click)='moveLeft()'><i id="arrow-left" class="fa fa-caret-left"></i></button>
          <button class="direction-btn-right" (click)='moveRight()' ><i id="arrow-left" class="fa fa-caret-right"></i></button>
  
          <drag-scroll #nav >
            <div drag-scroll-item *ngFor="let item of youtubeLst; let i = index">
              <div class="card clean-card text-center slick-card">
                <div class="card-body info">
                  <!-- <h2 class="card-titile">{{ item.code }}</h2>
                  <hr> -->
                  <br>
                  <br>
                  <a class="play-btn" href="https://www.youtube.com/watch?v={{item.code}}">
                    <img class="card-img w-100 d-block" src="{{ item.image }}">
                    <div class="centered"><img src="./assets/img/play-icon2.png" alt="">
                    </div>
                  </a>
                  <br>
                  <div style="min-height: 150px;">
                    <p class="font-para-16 card-text wrapWord">{{ item.desc }}</p>
                  </div>
                  <br><br>
  
                </div>
  
              </div>
            </div>
          </drag-scroll>
        </div>
      </div>
  
      <div data-aos="fade-left" class="vid-main-wrapper clearfix  visible-lg-block">
        <!-- THE YOUTUBE PLAYER -->
        <div class="vid-container" [innerHtml]="yt_iframe_html"></div>
  
        <!-- THE PLAYLIST -->
        <div class="vid-list-container">
          <ul id="vid-list">
            <li *ngFor="let id of youtubeLst">
              <!-- <div>{{id.name}}</div> -->
              <a (click)="changeSlide(id.code)">
                <span class="vid-thumb"><img width=160 src={{id.image}} /></span>
                <div class="desc">{{id.desc}}</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</div>
