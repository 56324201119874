<!-- start footer Area -->
<footer class="footer-area section-gap" style="background-image: url('./assets/img/footer-bg2.jpg'); background-repeat: no-repeat; background-size: cover;">
  <div class="container">
    <div class="row">
      <div class="col-sm-3">
        <div class="single-footer-widget">
          <h6>Get started</h6>
          <ul>
            <li>
              <i class="ti-angle-double-right"></i>
              <a [routerLink]="['/home']" [queryParams]="{ section: 'home' }"> Home</a>
            </li>
            <!-- <li>
                <i class="ti-angle-double-right"></i>
                <a target="_blank" [routerLink]="['/download']"> Downloads</a>
              </li> -->
          </ul>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="single-footer-widget">
          <h6>About us</h6>
          <ul>
            <!-- <li>
                <i class="ti-angle-double-right"></i>
                <a href="#">Company Information</a>
              </li> -->
            <li>
              <i class="ti-angle-double-right"></i>
              <a [routerLink]="['/home']" [queryParams]="{ section: 'aboutus' }"> About us</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="single-footer-widget">
          <h6>Support</h6>
          <ul>
            <li>
              <i class="ti-angle-double-right"></i>
              <a [routerLink]="['/faq']"> FAQ</a>
            </li>
            <li>
              <i class="ti-angle-double-right"></i>
              <a [routerLink]="['/download']"> LoRa Document</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-3">
        <!-- <div class="single-footer-widget">
            <h6>Legal</h6>
            <ul>
              <li>
                <i class="ti-angle-double-right"></i>
                <a target="_blank" [routerLink]="['/policy']"> Terms of Service</a>
              </li>
              <li>
                <i class="ti-angle-double-right"></i>
                <a target="_blank" [routerLink]="['/policy']"> Terms of Use</a>
              </li>
              <li>
                <i class="ti-angle-double-right"></i>
                <a target="_blank" [routerLink]="['/policy']"> Privacy Policy</a>
              </li>
            </ul>
          </div> -->
      </div>
    </div>
  </div>
  <div class="footer-copyright">
    &copy; {{year | date: 'yyyy'}}, LoRa IoT
    <i class="fa fa-paper-plane "></i> by
    <a href="https://lora.ntdigitalsolutions.com">NATIONAL TELECOM</a>
  </div>
</footer>
<!-- End footer Area -->