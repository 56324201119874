<header id="header">
  <div class="container">
    <div class="row align-items-center justify-content-between d-flex">
      <div id="logo">
        <a href="index.html">
          <img id="img-logo" src="./assets/img/logo.png" alt="" title="" />
        </a>
      </div>
      <nav id="nav-menu-container">
        <ul class="nav-menu">
          <li class="menu-active">
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#info">Info</a>
          </li>
          <li>
            <a href="#features">Features</a>
          </li>
          <li>
            <a href="#products">Products</a>
          </li>
          <li>
            <a href="#event">News & Events</a>
          </li>
          <li>
            <a [routerLink]="['/faq']">FAQ</a>
          </li>
          <li>
            <a href="#about-us">About us</a>
          </li>
          <!-- <li class="menu-has-children">
                <a href="">Pages</a>
                <ul>
                  <li>
                    <a href="generic.html">Generic</a>
                  </li>
                  <li>
                    <a href="elements.html">Elements</a>
                  </li>
                </ul>
              </li> -->
        </ul>
      </nav>
      <!-- #nav-menu-container -->
    </div>
  </div>
</header>
<!-- #header -->

<!-- About Generic Start -->
<div class="main-wrapper">
  <br>
  <br>
  <br>
  <section class="section-gap">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="menu-content pb-60 col-lg-10">
          <div class="title text-center">
            <h1 class="mb-10">Partner</h1>
            <p>Who are in extremely love with eco friendly system.</p>
          </div>
        </div>
      </div>
      <div class="row">
       
      </div>
    </div>
  </section>
 