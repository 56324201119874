<header id="header">
  <div class="container">
    <div class="row align-items-center justify-content-between d-flex">
      <div id="logo">
        <a href="index.html">
          <img id="img-logo" src="./assets/img/logo.png" alt="" title="" />
        </a>
      </div>
      <nav id="nav-menu-container">
        <ul class="nav-menu">
          <li class="menu-active">
            <a [routerLink]="['/home']">Home</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'info' }">Info</a>
          </li> -->
          <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'feature' }">Features</a>
          </li>
          <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'map-coverage' }">Coverage Area</a>
          </li>
          <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'products' }">Products</a>
          </li>
          <li>
            <a [routerLink]="['/events']">News & Events</a>
          </li>
          <li>
            <a [routerLink]="['/elearning']">E-Learning</a>
          </li>
          <li>
            <a [routerLink]="['/usecase']">Use Cases</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/home']" [queryParams]="{ section: 'aboutus' }">About us</a>
          </li> -->
          <li>
            <br><!-- <a [routerLink]="['/home']" [queryParams]="{ section: 'aboutus' }">About us</a> -->
          </li>
          <li class="hidden-md-up">
            <a href="javascript:void(0)">Support</a>
          </li>
          <li class="hidden-md-up">
            <a [routerLink]="['/faq']">&nbsp;&nbsp;> FAQ</a>
          </li>
          <li class="hidden-md-up">
            <a [routerLink]="['/download']">&nbsp;&nbsp;> LoRa Document</a>
          </li>
          <li class="hidden-sm-down">
            <div class="dropdown">
              <a href="javascript:void(0)">Support</a>
              <div class="dropdown-content">
                <a [routerLink]="['/faq']">FAQ</a>
                <a [routerLink]="['/download']"> LoRa Document</a>
              </div>
            </div>
          </li>

        </ul>

      </nav>
      <!-- #nav-menu-container -->
      <!-- <div id="language">
          <a href="javascript:void(0)" (click)="changeLanguage('en')"> <img [ngClass]="{'img-grey':this.language == 'en'}" id="img-lang" src="./assets/img/en.png" alt="" title="" /></a>  
          <a href="javascript:void(0)"(click)="changeLanguage('th')"><img [ngClass]="{'img-grey':this.language == 'th'}" id="img-lang" src="./assets/img/th.png" alt="" title="" /></a>
      </div> -->
    </div>
  </div>
</header>
<!-- #header -->

<!-- About Generic Start -->
<div class="main-wrapper">
  <br>
  <br>
  <br>
  <section id="usecase-area-id" class="section-gap">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="menu-content pb-60 col-lg-10">
          <div class="title text-center">
            <h1 class="mb-10">Use cases</h1>
            <p>ตัวอย่างโครงการที่ใช้งานร่วมกับ LoRa IoT</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <h3>IoT Showroom (PM 2.5)</h3> 
        <br><br>
        <div class="col-sm-12 col-lg-12">
            <div id="youtube-area" class="vid-container" [innerHtml]="yt_iframe_html"></div>
        </div>
        <br><br>
        <div class="col-sm-12 col-lg-12">
            <div id="youtube-area" class="vid-container" [innerHtml]="yt_iframe_html_1"></div>
        </div>
        <br><br>
        <div class="col-sm-12 col-lg-12">
            <div id="youtube-area" class="vid-container" [innerHtml]="yt_iframe_html_2"></div>
        </div>
        <br><br>
      </div>
      <br>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-lg-12">
          <img id="img-width" class="card-img w-100 d-block" src="assets/img/usecase7.1.jpg">
        </div>
        <a class="link" target="_blank" href="http://iot.spu.ac.th/pin/project/mushroom-001.php"><i
            class="ti-bar-chart"></i>
          เว็บไซต์แสดงข้อมูล Smart Farming ของ SPU</a><br>
        <div class="col-sm-12 col-lg-12">
          <img class="card-img w-100 d-block" src="assets/img/usecase1.jpg">
        </div>
        <div class="col-sm-12 col-lg-12"><br>
        </div>
        <div class="col-sm-12 col-lg-12">
          <img class="card-img w-100 d-block" src="assets/img/usecase2.jpg">
        </div>
        <div class="col-sm-12 col-lg-12"><br>
        </div>
        <div class="col-sm-12 col-lg-12">
          <img class="card-img w-100 d-block" src="assets/img/usecase3.jpg">
        </div>
        <div class="col-sm-12 col-lg-12"><br>
        </div>
        <div class="col-sm-12 col-lg-12">
          <img class="card-img w-100 d-block" src="assets/img/usecase4.jpg">
        </div>
        <div class="col-sm-12 col-lg-12"><br>
        </div>
        <div class="col-sm-12 col-lg-12">
          <img class="card-img w-100 d-block" src="assets/img/usecase5.jpg">
        </div>
        <div class="col-sm-12 col-lg-12"><br>
        </div>
        <div class="col-sm-12 col-lg-12">
          <img class="card-img w-100 d-block" src="assets/img/usecase6.jpg">
        </div>
        <div class="col-sm-12 col-lg-12"><br>
        </div>
        <div class="col-sm-12 col-lg-12"><br>
        </div>
        <div class="col-sm-12 col-lg-12">
          <img class="card-img w-100 d-block" src="assets/img/usecase8.jpg">
        </div>
        <div class="col-sm-12 col-lg-12"><br>
        </div>
        <div class="col-sm-12 col-lg-12">
          <img class="card-img w-100 d-block" src="assets/img/usecase9.jpg">
        </div>
        <div class="col-sm-12 col-lg-12"><br>
        </div>
        <div class="col-sm-12 col-lg-12">
          <img class="card-img w-100 d-block" src="assets/img/usecase10.jpg">
        </div>
      </div>
    </div>
  </section>


  <div class="content visible-md-block visible-lg-block">
    <div class="corner-ribbon bottom-right sticky green shadow"><a class="banner" [routerLink]="['/elearning']">E -
        LEARNING</a></div>
  </div>