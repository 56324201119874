import { Component, ElementRef, HostListener, NgZone, OnInit, ViewChild, Inject } from '@angular/core';

import { EmbedVideoService } from 'ngx-embed-video';

import { DragScrollComponent } from 'node_modules/ngx-drag-scroll';

import { TranslationService } from '../../services/translation.service';

import { ScrollToService } from 'ng2-scroll-to-el';

@Component({
  selector: 'app-elearning',
  templateUrl: './elearning.component.html',
  styleUrls: ['./elearning.component.css']
})
export class ElearningComponent implements OnInit {
  language: any;

  yt_iframe_html: any;
  youtubeUrl: any;
  youtubeLst: any;


  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;

  constructor(
    private embedService: EmbedVideoService,
    private scrollService: ScrollToService,
    private translator: TranslationService) {

    // this.translate = translate;
    // this.language = localStorage.getItem('lang')
    // this.translate.setDefaultLang(this.language);

    // // console.warn("localStorage LEARNING:", this.language);


    // if (this.language != null) {
    //   this.translate.use(this.language);
    // }
    // else {
    //   localStorage.setItem('lang', 'th');
    //   this.translate.setDefaultLang(this.language);
    // }

    this.youtubeLst = [{
      code: '-2b96R19lqo',
      image: 'assets/img/cover_overview.jpg',
      desc: 'LoRa IoT by CAT : Starter Kit Overview'
    },
    {
      code: 'Cwv-i3hC6F8',
      image: 'assets/img/cover_stm1.jpg',
      desc: 'LoRa IoT by CAT : Starter Kit STMicroelectronics Part 1'
    },
    {
      code: 'Ghk5EWnK718',
      image: 'assets/img/cover_stm2.jpg',
      desc: 'LoRa IoT by CAT : Starter Kit STMicroelectronics Part 2'
    },
    {
      code: 'o43Eu17mWRc',
      image: 'assets/img/cover_stm3.jpg',
      desc: 'LoRa IoT by CAT : Starter Kit STMicroelectronics Part 3'
    },
    {
      code: 'Wsh0l2Ke5E4',
      image: 'assets/img/cover_stm4.jpg',
      desc: 'LoRa IoT by CAT : Starter Kit STMicroelectronics Part 4'
    },
    {
      code: 'pRnVsH2Ioew',
      image: 'assets/img/cover_microchip.jpg',
      desc: 'LoRa IoT by CAT : Starter Kit Microchip Mote'
    },
    ]
  }


  // changeLanguage(lang: string) {
  //   console.log("lang:", lang);

  //   this.translate.use(lang);
  //   localStorage.setItem('lang', lang);
  //   this.language = lang;
  // }


  changeLanguage(lang: string) {
    // console.log("lang E-LEARNING :", lang);
  this.translator.setLanguage(lang);
  this.language = lang;
}


  ngOnInit() {
    this.scrollService.scrollTo("#elearning-area-id", 0, 0);
    // this.yt_iframe_html = this.embedService.embed('https://www.youtube.com/watch?v=iHhcHTlGtRs');
    this.yt_iframe_html = this.embedService.embed('https://www.youtube.com/watch?v=-2b96R19lqo', {
      attr: { width: 750, height: 400 },
    });

  }


  changeSlide(youtubeUrlId) {
    this.youtubeUrl = 'https://www.youtube.com/watch?v=' + youtubeUrlId;
    // this.yt_iframe_html = this.embedService.embed(this.youtubeUrl);
    this.yt_iframe_html = this.embedService.embed(this.youtubeUrl, {
      attr: { width: 750, height: 400 }
    });
  }


  moveLeft() {
    // this.ds.moveLeft();
    this.ds.moveLeft()
    // console.log('moveLeft');
  }

  moveRight() {
    this.ds.moveRight()
    // console.log('moveRight');

  }
  
}
