import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {
  
  readonly rootUrl = 'https://lora.ntdigitalsolutions.com/portal';

  constructor(private http: HttpClient) { }

  serviceRequest(requestobject) {

    var reqHeader = new HttpHeaders({
     'Authorization': 'Bearer ' + localStorage.getItem('userToken-admin'),
     'Content-Type': 'application/json; charset=utf-8'
   });

   switch (requestobject.method) {
     case 'GET':
       return this.http.get(this.rootUrl + requestobject.url, { headers: reqHeader });
     case 'POST':
       return this.http.post(this.rootUrl + requestobject.url, requestobject.data, { headers: reqHeader });
     case 'PUT':
       return this.http.put(this.rootUrl + requestobject.url, requestobject.data, { headers: reqHeader });
     case 'DELETE':
       return this.http.delete(this.rootUrl + requestobject.url, { headers: reqHeader });
     default:
       break;
   }
 }
}
